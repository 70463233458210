import { scale } from 'chroma-js';
import { sortBy, findIndex, isNull, isUndefined, isNumber } from 'lodash-es';

type ColourList = string[];
type Range = { upper: number; lower: number };

const GREY = '#c1c7c9';

const colours: { [key: string]: ColourList } = {
  default: ['#B31C08', '#E6E60B', '#1FB308'],
};

function getColoursForType(type: string) {
  return colours[type] || colours.default;
}

function generateColourScale(
  type: string,
  range = { upper: 1, lower: 0 },
  scaleLength = 1,
) {
  return scale(getColoursForType(type))
    .domain([range.lower, range.upper])
    .classes(scaleLength);
}

export function getColourForValue(
  value: number,
  type: string,
  range: Range,
  scaleLength: number,
) {
  if (isNull(value) || isUndefined(value)) {
    return GREY;
  }
  return generateColourScale(type, range, scaleLength)(value).hex();
}

export function getPercentileRange(data: number[]) {
  const sorted = sortBy(data);

  const quantile = (arr: number[], q: number) => {
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;

    return (
      Math.round(
        (sorted[base + 1] !== undefined
          ? sorted[base] + rest * (sorted[base + 1] - sorted[base])
          : sorted[base]) / 10,
      ) * 10
    );
  };

  return [
    quantile(sorted, 0),
    quantile(sorted, 0.25),
    quantile(sorted, 0.5),
    quantile(sorted, 0.75),
    quantile(sorted, 1),
  ];
}

export function getColourByPercentileRange(
  percentileRange: number[],
  colourList: ColourList,
  value: number,
) {
  const getPercentileIndex = () =>
    findIndex(percentileRange, percentileValue => value < percentileValue) - 1;

  return value < percentileRange[0] || value > percentileRange[4]
    ? GREY
    : colourList[getPercentileIndex()];
}

export const getRangedPalette = (colourList: ColourList, range: Range) => {
  const { upper, lower } = range;
  const rangeWidth = (upper - lower) / colourList.length;

  return colourList.map((colour: string, index: number) => ({
    colour,
    from: rangeWidth * index + lower,
    to: rangeWidth * (index + 1) + lower,
  }));
};

const getColourForRange = (
  value: number,
  colourList: ColourList,
  range: Range,
) => {
  const targetPalette = getRangedPalette(colourList, range).find(
    rangedColour => rangedColour.from <= value && rangedColour.to >= value,
  );

  return targetPalette
    ? targetPalette.colour
    : colourList[colourList.length - 1];
};

export function getColourForValueFromColourList(
  value: number,
  colourList: ColourList,
  range: Range,
) {
  return isNull(value) || isUndefined(value) || !isNumber(value)
    ? GREY
    : getColourForRange(value, colourList, range);
}

export function calculateValueForStop(
  scaleStop: { min: number; max: number },
  range = { upper: 1, lower: 0 },
) {
  if (!scaleStop.min && scaleStop.max) {
    return range.lower;
  }
  if (!scaleStop.max && scaleStop.min) {
    return range.upper;
  }
  const minMinusMax = (scaleStop.max - scaleStop.min) / 2;

  return minMinusMax + scaleStop.min;
}
