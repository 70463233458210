import React from 'react';
import { createUseStyles } from 'react-jss';
import { MediaQueries } from '@hummingbirdtechgroup/crips-ui';
import { DefaultTheme } from '../../../styles/defaultTheme';

type Props = {
  children: React.ReactNode;
};
const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    [MediaQueries.medium]: {
      '&:after': {
        content: "''",
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundImage: `url('https://storage.googleapis.com/hummingbirdtech-production-web-static-assets/images/fresh-flight-request-background.png')`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'right',
        backgroundPositionY: 'center',
      },
    },
    '@media(min-width:1400px)': {
      '&:after': {
        left: theme.spacing(60),
        backgroundPositionX: 'center',
      },
    },
  },
}));

const FlightRequestBackground = ({ children }: Props) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

export default FlightRequestBackground;
