import React from 'react';
import {
  ActionLink,
  PlusIcon,
  MediaQueries,
} from '@hummingbirdtechgroup/crips-ui';
import { createUseStyles } from 'react-jss';
import { DefaultTheme } from 'styles/defaultTheme';
import { EventCategory } from 'constants/eventCategory';
import { EventAction } from 'constants/eventAction';
import { useGoogleAnalyticsEvent } from 'libraries/googleAnalytics';

type Props = {
  farmId: string;
  fieldId: string;
};

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    height: theme.spacing(5),
    width: 'auto',
    paddingLeft: theme.spacing(1),
    [MediaQueries.medium]: {
      height: theme.spacing(6),
      minWidth: theme.spacing(16),
      paddingLeft: 0,
    },
  },
  text: {
    display: 'none',
    [MediaQueries.medium]: {
      display: 'inline',
    },
  },
}));

const NewFlightLink = ({ farmId, fieldId }: Props) => {
  const styles = useStyles();
  const fireAnalyticsEvent = useGoogleAnalyticsEvent();
  return (
    <ActionLink
      className={styles.root}
      to={{
        pathname: '/flight/new',
        search: `selectedFarm=${farmId}&fields[0]=${fieldId}`,
      }}
      variant="secondary"
      Icon={PlusIcon}
      onClick={() =>
        fireAnalyticsEvent(
          EventCategory.FLIGHT,
          EventAction.REQUEST,
          'From header',
        )
      }
    >
      <span className={styles.text}>New flight</span>
    </ActionLink>
  );
};

export default NewFlightLink;
