import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  FontSizes,
  FontWeights,
  LeafIcon,
  SizeIcon,
  ZoneIcon,
} from '@hummingbirdtechgroup/crips-ui';
import { Hectares } from '@hummingbirdtechgroup/wings-unit-conversion';
import fonts from '@hummingbirdtechgroup/crips-ui/dist/tokens/fonts';
import { LoadingMessage } from '../index';
import { DefaultTheme } from '../../styles/defaultTheme';

const itemGrey = '#505271';

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  detailsList: {
    display: 'flex',
    gap: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  detail: {
    fontSize: FontSizes.Small,
    lineHeight: `${theme.spacing(2)}px`,
    fontFamily: fonts.roboto,
    fontWeight: FontWeights.Regular,
    paddingLeft: theme.spacing(2.5),
    color: itemGrey,
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));

function FieldDetails({
  cropName,
  varietyName,
  area,
  zonesCount,
  loadingCrops,
  loadingZones,
}: {
  area?: number;
  cropName?: string;
  varietyName?: string;
  loadingCrops: boolean;
  loadingZones: boolean;
  zonesCount: number;
}) {
  const classes = useStyles();

  return (
    <ul className={classes.detailsList}>
      {loadingCrops && (
        <li>
          <LoadingMessage variant="small">Loading crop...</LoadingMessage>
        </li>
      )}
      {cropName && (
        <li className={classes.detail}>
          <LeafIcon className={classes.icon} /> {cropName} / {varietyName}
        </li>
      )}
      <li className={classes.detail}>
        <SizeIcon className={classes.icon} />{' '}
        <Hectares precision={0}>{area || 0}</Hectares>
      </li>
      {loadingZones ? (
        <li>
          <LoadingMessage variant="small">Loading zone...</LoadingMessage>
        </li>
      ) : (
        <li className={classes.detail}>
          <ZoneIcon className={classes.icon} />
          {zonesCount} zones
        </li>
      )}
    </ul>
  );
}

export default FieldDetails;
