import { FontSizes, FontWeights } from '@hummingbirdtechgroup/crips-ui';
import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { DefaultTheme } from 'styles/defaultTheme';

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    background: 'rgba(255, 255, 255, 0.5)',
    borderRadius: '4px',
    opacity: '1',
    backdropFilter: 'blur(31px)',
    fontSize: FontSizes.Regular,
    fontWeight: FontWeights.Light,
    padding: [theme.spacing(0.5), theme.spacing(1)],
  },
  disabled: {
    opacity: 0.5,
  },
  label: {
    marginRight: theme.spacing(2),
  },
  switchOutline: {
    display: 'block',
    width: theme.spacing(4),
    height: theme.spacing(2),
    backgroundColor: theme.palette.switchBackground.default,
    borderRadius: theme.spacing(2),
    transition: 'background-color, .3s',
    '&.checked': {
      backgroundColor: theme.palette.success.main,
    },
  },
  switchBullet: {
    display: 'block',
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: theme.spacing(2),
    boxSizing: 'border-box',
    border: `solid 1px ${theme.palette.switchBackground.default}`,
    backgroundColor: theme.palette.background.default,
    marginLeft: 0,
    transition: 'margin-left, .3s',
    '&.checked': {
      marginLeft: theme.spacing(2),
    },
  },
}));

interface Props {
  label: React.ReactNode;
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled: boolean;
}

const MapSwitch = ({ label, checked, onChange, disabled }: Props) => {
  const classes = useStyles();
  return (
    <button
      className={classNames(classes.root, { [classes.disabled]: disabled })}
      aria-hidden="true"
      onClick={() => onChange(!checked)}
      disabled={disabled}
    >
      {!!label && <span className={classes.label}>{label}</span>}
      <span className={classNames(classes.switchOutline, { checked })}>
        <span className={classNames(classes.switchBullet, { checked })} />
      </span>
    </button>
  );
};

export default MapSwitch;
