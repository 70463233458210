import { get } from 'lodash-es';
import useProductQuery from '../useProductQuery/useProductQuery';
import useProductFile from '../useProductFile/useProductFile';
import { Product } from '../useProductQuery/product';

export type Level = {
  feature_id: number;
  cloudy: boolean;
  product_rate: number;
  hectares: number;
  override_value: number | null;
};

export type Metadata = {
  levels: Level[];
  max_product_rate: number;
  min_product_rate: number;
  max_application_rate: number;
  number_of_points: number;
  inverted: boolean;
  levels_count: number;
};

export type Application = {
  geojson: any;
  metadata: Metadata;
};

type Response = {
  product?: Product;
  data?: Application;
  isLoading: boolean;
  isError: boolean;
};

const useApplication = (applicationId: string, skip: boolean): Response => {
  const {
    data: productData,
    isLoading: productIsLoading,
    isError: productIsError,
  } = useProductQuery(applicationId, {
    enabled: !skip,
  });

  const applicationData = get(productData, 'files[0]', {});

  const {
    data,
    isLoading: appFileIsLoading,
    isError: appFileIsError,
  } = useProductFile(applicationData.id, applicationData.signed_url, {
    enabled: !skip && !!applicationData.signed_url,
  });

  return {
    product: productData,
    data: data as Application,
    isLoading: productIsLoading || appFileIsLoading,
    isError: productIsError || appFileIsError,
  };
};

export default useApplication;
