import React from 'react';
import { createUseStyles } from 'react-jss';
import * as L from 'leaflet';
import { GeoJSON, MapContainer } from 'react-leaflet';
import { Colours, FontWeights } from '@hummingbirdtechgroup/crips-ui';
import fonts from '@hummingbirdtechgroup/crips-ui/dist/tokens/fonts';
import { DefaultTheme } from 'styles/defaultTheme';
import { useParseGeometry } from 'services';
import { FieldDetails } from '../../components';
import { Field } from './useFields';
import FieldLink from './FieldLink';

type Props = {
  farmId: string;
  field: Field;
  loadingCrops: boolean;
  loadingZones: boolean;
};

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    borderRadius: theme.spacing(0.5),
    marginTop: theme.spacing(2),
    boxShadow: `3px 0 15px ${Colours.quarternary}, 0 3px 15px ${Colours.quarternary}`,
    backgroundColor: Colours.white,
    paddingRight: theme.spacing(9),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    position: 'relative',
    '&:hover': {
      backgroundColor: Colours.septernary,
    },
  },
  header: {
    fontSize: '1.4rem',
    fontFamily: fonts.helveticaNeue,
    color: Colours.primary,
    lineHeight: '2rem',
    fontWeight: FontWeights.Bold,
    paddingTop: theme.spacing(2),
  },
  preview: {
    width: theme.spacing(6),
    position: 'absolute',
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),
  },
}));

const FieldItem = ({ farmId, field, loadingCrops, loadingZones }: Props) => {
  const classes = useStyles();

  const [geometry, bounds] = useParseGeometry(field?.geometry);

  return (
    <li className={classes.root}>
      <h3 className={classes.header}>
        <FieldLink to={`/farm/${farmId}/field/${field.id}`}>
          {field.name}
        </FieldLink>
      </h3>
      <FieldDetails
        loadingCrops={loadingCrops}
        loadingZones={loadingZones}
        area={field.area}
        zonesCount={field.zonesData.length || 0}
        varietyName={field.varietyName}
        cropName={field.cropName}
      />
      <div className={classes.preview}>
        {!!geometry && (
          <MapContainer
            bounds={bounds}
            attributionControl={false}
            maxZoom={22}
            doubleClickZoom={false}
            dragging={false}
            keyboard={false}
            scrollWheelZoom={false}
            boxZoom={false}
            tap={false}
            zoomControl={false}
            preferCanvas
            renderer={L.canvas()}
            style={{ height: 56, backgroundColor: 'transparent' }}
          >
            <GeoJSON
              style={{
                color: Colours.primary,
                weight: 1,
                fillColor: Colours.primary,
                fillOpacity: 0.2,
              }}
              data={geometry}
            />
          </MapContainer>
        )}
      </div>
    </li>
  );
};

export default FieldItem;
