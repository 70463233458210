import { useMemo } from 'react';
import { Feature } from '@turf/helpers';
import { geoJSON, LatLngBounds } from 'leaflet';

type HookReturnType = [Feature | undefined, LatLngBounds | undefined];

const useParseGeometry = (geometryJSON?: string): HookReturnType => {
  const geometry: Feature | undefined = useMemo(() => {
    if (geometryJSON) {
      try {
        return JSON.parse(geometryJSON);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('cant parse base zone geometry', err);
      }
    }
    return undefined;
  }, [geometryJSON]);
  const bounds = geometry && geoJSON(geometry).getBounds();

  return [geometry, bounds];
};

export default useParseGeometry;
