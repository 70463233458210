import {
  Colours,
  FontWeights,
  H3,
  P,
  SizeIcon,
  Checkbox,
  FontSizes,
  LeafIcon,
} from '@hummingbirdtechgroup/crips-ui';
import classNames from 'classnames';
import * as L from 'leaflet';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { MapContainer, GeoJSON } from 'react-leaflet';
import { Hectares } from '@hummingbirdtechgroup/wings-unit-conversion';
import { useParseGeometry, useSearchParams } from 'services';
import useFieldCrop from 'services/useFieldCrop/useFieldCrop';
import { DefaultTheme } from 'styles/defaultTheme';
import { handleBigSearchParamsList } from '../utils/handleBigSearchParamsList';
import CropName from './CropName';
import { LoadingMessage } from '../../../components';

const useStyles = createUseStyles(({ spacing }: DefaultTheme) => ({
  root: {
    position: 'relative',
    height: '7.2rem',
    backgroundColor: Colours.white,
    marginTop: spacing(2),
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    boxShadow: `3px 0px 15px ${Colours.quarternary},0px 3px 15px ${Colours.quarternary}`,
    padding: `0 ${spacing(2)}px`,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: Colours.septernary,
    },
  },
  rootSelected: {
    backgroundColor: Colours.quarternary,
    border: `1px solid ${Colours.primary}`,
  },
  itemHeader: {
    fontSize: FontSizes.Regular,
    fontWeight: FontWeights.Bold,
    marginBottom: '0.8rem',
  },
  centeredVerticalRow: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldsInfo: {
    width: '100%',
    marginLeft: spacing(2),
  },
  marginRight: {
    marginRight: spacing(2),
  },
  fieldData: {
    fontSize: FontSizes.Small,
    fontWeight: FontWeights.Regular,
    color: Colours.sexternary,
    marginLeft: 4,
  },
  clickOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  fieldPreview: {
    width: spacing(6),
    position: 'absolute',
    top: spacing(1.5),
    right: spacing(1.5),
  },
}));

const selectionHandler = (
  setQueryParams: (...args: any[]) => void,
  urlQueryState: any,
  fieldId: string,
  checked: boolean,
  fieldsArray: number[] = [],
) => {
  let fields;
  if (checked) {
    fields = [...fieldsArray, fieldId];
  } else if (!checked && urlQueryState.fields) {
    fields = fieldsArray.filter(
      (field: number) => field.toString() !== fieldId,
    );
  }
  setQueryParams({
    ...urlQueryState,
    fields,
  });
};

type Props = {
  field: any;
  selectedFarm?: number;
};

export default function FieldItem({ field, selectedFarm }: Props) {
  const classes = useStyles();
  const [urlQueryState, setQueryParams] = useSearchParams();
  const { fields: urlFields } = urlQueryState;
  const [checked, setChecked] = useState<boolean>(
    urlFields?.includes(field.id),
  );

  const [geometry, bounds] = useParseGeometry(field?.baseZoneGeometry);

  const { data: cropData, loading: loadingCrops } = useFieldCrop(field.id);

  const fieldsArray = urlFields
    ? handleBigSearchParamsList(urlFields)
    : urlFields;

  const onCheck = (val: boolean) => {
    setChecked(val);
    selectionHandler(setQueryParams, urlQueryState, field.id, val, fieldsArray);
  };

  return (
    <div
      className={classNames(classes.root, { [classes.rootSelected]: checked })}
    >
      <Checkbox
        id={field.id}
        checked={checked}
        onChange={() => onCheck(!checked)}
      />
      <section className={classes.fieldsInfo}>
        <H3 className={classes.itemHeader}>{field.name}</H3>
        <section className={classNames(classes.centeredVerticalRow)}>
          {!loadingCrops && (
            <div
              className={classNames(
                classes.centeredVerticalRow,
                classes.marginRight,
              )}
            >
              <LeafIcon />
              <CropName classes={classes.fieldData} cropData={cropData} />
            </div>
          )}
          {loadingCrops && (
            <LoadingMessage variant="small" className={classes.marginRight}>
              Loading crop...
            </LoadingMessage>
          )}
          <div className={classes.centeredVerticalRow}>
            <SizeIcon />
            <P className={classes.fieldData}>
              <Hectares precision={0}>
                {Math.round(field.baseZoneArea)}
              </Hectares>
            </P>
          </div>
        </section>
      </section>
      <div className={classes.fieldPreview}>
        {!!geometry && (
          <MapContainer
            bounds={bounds}
            attributionControl={false}
            maxZoom={22}
            doubleClickZoom={false}
            dragging={false}
            keyboard={false}
            scrollWheelZoom={false}
            boxZoom={false}
            tap={false}
            zoomControl={false}
            preferCanvas
            renderer={L.canvas()}
            style={{ height: 50, backgroundColor: 'transparent' }}
          >
            <GeoJSON
              style={{
                color: Colours.primary,
                weight: 1,
                fillColor: Colours.primary,
                fillOpacity: 0.2,
              }}
              data={geometry}
            />
          </MapContainer>
        )}
      </div>
    </div>
  );
}
