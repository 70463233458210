import * as React from 'react';
import * as Leaflet from 'leaflet';
import 'leaflet.gridlayer.googlemutant/dist/Leaflet.GoogleMutant';
import {
  createLayerComponent,
  updateGridLayer,
  LeafletContextInterface,
  LayerProps,
} from '@react-leaflet/core';
import { Loader, LoaderOptions } from '@googlemaps/js-api-loader';

interface IGoogleMapsAddLayer {
  name: 'BicyclingLayer' | 'TrafficLayer' | 'TransitLayer';
  options?: any;
}

// @ts-ignore
interface IProps extends Leaflet.gridLayer.GoogleMutantOptions {
  zIndex?: number;
  useGoogMapsLoader?: boolean;
  googleMapsLoaderConf?: LoaderOptions;
  googleMapsAddLayers?: IGoogleMapsAddLayer[];
  apiKey?: string;
}

let googleMapsScriptLoaded = false;

let instance: any;

const createLeafletElement = (
  props: IProps,
  context: LeafletContextInterface,
) => {
  const {
    apiKey = '',
    useGoogMapsLoader = true,
    googleMapsLoaderConf = {},
    googleMapsAddLayers,
    ...googleMutantProps
  } = props;
  if (instance === undefined) {
    if (useGoogMapsLoader && !googleMapsScriptLoaded) {
      const loader = new Loader({ apiKey, ...googleMapsLoaderConf });
      loader.load();
      googleMapsScriptLoaded = true;
    }
    // @ts-ignore
    instance = Leaflet.gridLayer.googleMutant(googleMutantProps);

    if (googleMapsAddLayers) {
      googleMapsAddLayers.forEach(layer => {
        // @ts-ignore
        (instance as Leaflet.gridLayer.GoogleMutant).addGoogleLayer(
          layer.name,
          layer.options,
        );
      });
    }
  }
  return { instance, context };
};

export default createLayerComponent<Leaflet.GridLayer, LayerProps & IProps>(
  createLeafletElement,
  updateGridLayer,
);
