import React from 'react';
import { ThemeProvider } from 'react-jss';
import {
  AuthProvider,
  useAuth,
  LogoutRedirect,
} from '@hummingbirdtechgroup/wings-auth';
import { useIntercomMessenger } from '@hummingbirdtechgroup/wings-intercom';
import { QueryClientProvider } from 'react-query';
import defaultTheme from 'styles/defaultTheme';
import { Router } from 'react-router-dom';
import { parse, stringify } from 'qs';
import { createBrowserHistory } from 'history';
import qhistory from 'qhistory';
import {
  ConfigProvider,
  AppEnvironment,
} from '@hummingbirdtechgroup/wings-config';
import { IntlProvider } from 'react-intl';
import {
  GoogleAnalytics,
  useGoogleAnalyticsEvent,
} from 'libraries/googleAnalytics';
import { hotjar } from 'react-hotjar';
import { UnitConversionProvider } from '@hummingbirdtechgroup/wings-unit-conversion';
import FreshApolloProvider from './FreshApolloProvider';
import AppRouter from './AppRouter';
import queryClient from './queryClient';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
process.env.API_ENV && hotjar.initialize(2658941, 6);

const messages = {};

const browserHistory = qhistory(createBrowserHistory(), stringify, parse);

function AppLayout() {
  const { user } = useAuth();

  useIntercomMessenger({ user });
  useGoogleAnalyticsEvent();

  // @ts-ignore
  if (user?.id) return <LogoutRedirect />;

  return (
    <>
      <AppRouter />
      {/* Analytics and logging */}
      {process.env.API_ENV === 'production' && <GoogleAnalytics />}
    </>
  );
}

function App(): React.ReactElement {
  return (
    <QueryClientProvider client={queryClient}>
      <FreshApolloProvider>
        <AuthProvider>
          {/* @ts-ignore */}
          <ThemeProvider theme={defaultTheme}>
            <IntlProvider
              locale="en"
              defaultLocale="en"
              messages={{ ...messages }}
            >
              <Router history={browserHistory as any}>
                <UnitConversionProvider>
                  <AppLayout />
                </UnitConversionProvider>
              </Router>
            </IntlProvider>
          </ThemeProvider>
        </AuthProvider>
      </FreshApolloProvider>
    </QueryClientProvider>
  );
}

function AppContainer(): React.ReactElement {
  return (
    <ConfigProvider env={process.env.API_ENV as AppEnvironment}>
      <App />
    </ConfigProvider>
  );
}

export default AppContainer;
