import React, { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { MediaQueries, Colours } from '@hummingbirdtechgroup/crips-ui';
import { DefaultTheme } from 'styles/defaultTheme';
import PageTitle from './PageTitle';

type Props = {
  children?: ReactNode;
  pageTitle?: ReactNode;
  backLink?: React.ReactElement;
};

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    width: '100%',
    backgroundColor: Colours.white,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    position: 'relative',
    [MediaQueries.large]: {
      flexWrap: 'nowrap',
      height: theme.spacing(8),
      position: 'absolute',
      top: 0,
      zIndex: 1000,
    },
  },
  pageTitle: {
    paddingRight: theme.spacing(8.5),
  },
}));

const PageHeader = ({ children, pageTitle, backLink }: Props) => {
  const classes = useStyles();
  return (
    <header className={classes.root}>
      {!!pageTitle && (
        <PageTitle
          className={classes.pageTitle}
          pageTitle={pageTitle}
          backLink={backLink}
        />
      )}
      {children}
    </header>
  );
};

export default PageHeader;
