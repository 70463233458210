/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  ActionLink,
  ActionButton,
  Colours,
  FontWeights,
  H3,
  InfoCircleIcon,
  P,
  PencilIcon,
  TextInput,
  FontSizes,
} from '@hummingbirdtechgroup/crips-ui';
import classNames from 'classnames';
import React, { FormEvent, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Hectares } from '@hummingbirdtechgroup/wings-unit-conversion';
import { useFarmsForUser, useMapFields, useSearchParams } from 'services';
import { MapFields_mapFields } from 'services/useMapFields/MapFields.gql';
import { ToolBox, ToolBoxHeader, ToolsPanel } from 'components/Layout';
import { Tooltip } from 'components';
import { styles as toolBoxHeaderStyles } from 'components/Layout/ToolBoxHeader';
import { EventCategory } from 'constants/eventCategory';
import { EventAction } from 'constants/eventAction';
import { useGoogleAnalyticsEvent } from 'libraries/googleAnalytics';
import useFlightRequest from 'services/useFlightRequest/useFlightRequest';
import dateToString from 'utilities/dateToString';
import { handleBigSearchParamsList } from '../utils/handleBigSearchParamsList';
import FlightRequestBackground from './FlightRequestBackground';
import { DefaultTheme } from '../../../styles/defaultTheme';

const offsetDate = (date: Date | number, offset: number) => {
  const initialDate = new Date(date);
  const finalDate = initialDate.setDate(initialDate.getDate() + offset);
  return new Date(finalDate);
};

const useStyles = createUseStyles(({ spacing }: DefaultTheme) => ({
  panelTitle: toolBoxHeaderStyles,
  editButtonContainer: {
    textAlign: 'center',
    padding: 8,
  },
  editButton: {
    display: 'inline-block',
    color: Colours.primary,
    borderRadius: 3,
    fontSize: FontSizes.Small,
    lineHeight: `${spacing(3)}px`,
    height: spacing(3),
    fontWeight: FontWeights.Regular,
    padding: `0 4px`,
    '&:hover': {
      backgroundColor: Colours.quarternary,
    },
  },
  editIcon: { top: 2, position: 'relative', height: 14 },
  textArea: {
    marginBottom: '1.7rem',
    '& textarea': {
      width: '100%',
      minHeight: 152,
    },
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    marginRight: '1.6rem',
  },
  datePicker: {
    height: '5.6rem',
    width: '14.4rem',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '1.4rem',
    backgroundColor: Colours.inputBackground,
    marginBottom: '2.4rem',
  },
  datePickerMargin: {
    marginRight: '1.6rem',
  },
  label: {
    display: 'inline-flex',
    flexDirection: 'column',
  },
  labelText: {
    fontSize: '1.2rem',
    color: Colours.sexternary,
    marginTop: '0.8rem',
    marginBottom: '0.8rem',
  },
  fieldsList: {
    height: '20rem',
    width: '100%',
    border: `1px solid ${Colours.quinternary}`,
    boxSizing: 'border-box',
    borderRadius: '4px',
    padding: '0.8rem',
    marginTop: '0.8rem',
    fontSize: '1.4rem',
  },
  totalArea: {
    color: Colours.sexternary,
    marginLeft: '0.4rem',
    fontSize: '1.4rem',
    '&::before': {
      content: '"\u25CF"',
      fontSize: '1.4rem',
      marginRight: '0.4rem',
    },
  },
  rangeTitleWrapper: {
    marginTop: spacing(1),
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },

  rangeTitleWrapperHovered: {
    '&::after': {
      backgroundColor: 'blue',
    },
    '&::before': {
      backgroundColor: 'blue',
      zIndex: 1,
    },
  },
  iconWithTooltip: {
    marginLeft: '0.8rem',
    error: {
      fontSize: '1.2rem',
      color: Colours.danger,
    },
  },
  error: {
    fontSize: '1.2rem',
    color: Colours.danger,
  },
  iconRoot: {
    color: Colours.sexternary,
  },
  buttonText: {
    color: Colours.white,
  },
  link: {
    alignSelf: 'center',
  },
  block: {
    whiteSpace: 'nowrap',
    display: 'block',
  },
}));

const NOW: number = Date.now();
const WEEK = 7;

export default function FlightCheckout() {
  const classes = useStyles();
  const history = useHistory();
  const [filteredFields, setFilteredFields] =
    useState<(MapFields_mapFields | null)[]>();
  const [{ fields: fieldIds, selectedFarm: urlSelectedFarm }] =
    useSearchParams();
  const [totalArea, setTotalArea] = useState<any>();
  const { data: farms } = useFarmsForUser();
  const { data: farmFields } = useMapFields(urlSelectedFarm);

  const [toDate, setMinToDate] = useState<Date>(offsetDate(NOW, WEEK * 2));
  const [fromDate, setFromDate] = useState<Date>(new Date(NOW));
  const [additionalInfo, setAdditionalInfo] = useState<string>('');

  const { search } = useLocation();

  const analyticsEvent = useGoogleAnalyticsEvent();

  const { post, isLoading, isError, isSuccess } = useFlightRequest();

  const submitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    analyticsEvent(EventCategory.FLIGHT, EventAction.SUBMIT);

    const selectedFarm = farms?.farms?.find(
      item => item?.id === Number(urlSelectedFarm),
    );

    const selectedFields = farmFields?.mapFields
      ? fieldIds?.map((id: string) =>
          farmFields?.mapFields?.find(mapField => id === mapField?.id),
        )
      : fieldIds.map((id: string) => ({ id }));

    post(selectedFarm, selectedFields, fromDate, toDate, additionalInfo);
  };

  useEffect(() => {
    if (fieldIds && farmFields?.mapFields) {
      const fieldsArray = handleBigSearchParamsList(fieldIds);
      setFilteredFields(
        farmFields?.mapFields?.filter((field: MapFields_mapFields | null) =>
          fieldsArray.includes(field?.id),
        ),
      );
    }
  }, [farmFields, fieldIds]);

  useEffect(() => {
    const intermediateValue: number[] = [];
    if (filteredFields) {
      filteredFields.forEach((field: MapFields_mapFields | null) => {
        if (typeof field?.baseZoneArea === 'number')
          intermediateValue.push(field.baseZoneArea);
      });
      setTotalArea(
        Math.round(intermediateValue.reduce((curr, prev) => curr + prev, 0)),
      );
    }
  }, [filteredFields]);

  useEffect(() => {
    if (isSuccess) {
      history.push('/flight/confirmation');
    }
  }, [isSuccess]);

  return (
    <FlightRequestBackground>
      <ToolsPanel>
        <ToolBox>
          <ToolBoxHeader>
            Selected fields
            {filteredFields && (
              <span className={classes.totalArea}>
                {filteredFields?.length} (
                <Hectares precision={0}>{totalArea}</Hectares>)
              </span>
            )}
          </ToolBoxHeader>
          <textarea
            className={classes.fieldsList}
            disabled
            value={filteredFields?.map(field => field?.name).join(', ')}
          />

          <div className={classes.editButtonContainer}>
            <Link to={`/flight/new${search}`} className={classes.editButton}>
              <PencilIcon className={classes.editIcon} />
              Edit Section
            </Link>
          </div>
          <form onSubmit={submitHandler}>
            <section>
              <div className={classNames(classes.rangeTitleWrapper)}>
                <H3 className={classes.panelTitle}>
                  Select preferred date range
                </H3>
                <Tooltip
                  message={
                    <span>
                      <span className={classes.block}>
                        We need a time window of at least{' '}
                      </span>
                      <span className={classes.block}>
                        7 days to arrange a pilot
                      </span>
                    </span>
                  }
                  position="right"
                  className={classes.iconWithTooltip}
                >
                  <InfoCircleIcon className={classes.iconRoot} />
                </Tooltip>
              </div>
              <label className={classes.label}>
                <span className={classes.labelText}>From</span>
                <input
                  className={classNames(
                    classes.datePicker,
                    classes.datePickerMargin,
                  )}
                  type="date"
                  name="from"
                  id="from"
                  onChange={({ target }) => {
                    setMinToDate(offsetDate(new Date(target.value), WEEK));
                    setFromDate(new Date(target.value));
                  }}
                  min={dateToString(offsetDate(NOW, WEEK))}
                  defaultValue={dateToString(offsetDate(NOW, WEEK))}
                />
              </label>
              <label className={classes.label}>
                <span className={classes.labelText}>To</span>
                <input
                  className={classes.datePicker}
                  type="date"
                  name="to"
                  id="to"
                  min={dateToString(offsetDate(NOW, WEEK * 2))}
                  onChange={({ target }) =>
                    setMinToDate(new Date(target.value))
                  }
                  value={dateToString(toDate)}
                />
              </label>
            </section>
            <section>
              <H3 className={classes.panelTitle}>Additional info</H3>
              <TextInput
                className={classes.textArea}
                labelText=""
                type="textarea"
                // @ts-ignore
                value={additionalInfo}
                onChange={({ target: { value } }) => setAdditionalInfo(value)}
              />
              {isError && (
                <P className={classes.error}>
                  Failed to submit your request. Please try again!
                </P>
              )}
              <div className={classes.buttonsWrapper}>
                <ActionLink
                  to="/"
                  variant="secondary"
                  className={classes.button}
                >
                  Cancel
                </ActionLink>
                <ActionButton type="submit" disabled={isLoading}>
                  Submit
                </ActionButton>
              </div>
            </section>
          </form>
        </ToolBox>
      </ToolsPanel>
    </FlightRequestBackground>
  );
}
