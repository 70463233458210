import { useQuery, UseQueryResult } from 'react-query';
import { get } from '@hummingbirdtechgroup/wings-request';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { Product } from 'types/Product';

const ONE_DAY = 8600000;

export default function useSurveyAnalyses(
  surveyId?: string,
): UseQueryResult<{ products: Product[] }, Error> {
  const config = useConfig();
  return useQuery(
    `survey-analyses-${surveyId}`,
    () =>
      get(
        config.apiUrl,
        `/v1/products?classes=analyses&survey_id=${surveyId}&signed_url=1`,
      ),
    {
      enabled: !!surveyId,
      staleTime: ONE_DAY,
    },
  );
}
