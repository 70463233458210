import React from 'react';
import { MediaQueries } from '@hummingbirdtechgroup/crips-ui';
import { createUseStyles } from 'react-jss';
import { DefaultTheme } from 'styles/defaultTheme';
import { FieldDetails } from '../FieldDetails';

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    width: '100%',
    [MediaQueries.medium]: {
      width: 'auto',
    },
  },
  fieldDetailsList: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'space-around',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    [MediaQueries.medium]: {
      marginLeft: theme.spacing(1.25),
      marginRight: 0,
    },
  },
  fieldDetailsItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    marginLeft: theme.spacing(0.75),
    fontSize: 12,
    fontWeight: 300,
  },
  actionList: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(2),
    display: 'flex',
    [MediaQueries.medium]: {
      marginTop: 0,
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      position: 'absolute',
      top: 0,
      right: 0,
      justifyContent: 'center',
    },
  },
}));

interface Props {
  area?: number;
  cropName?: string;
  varietyName?: string;
  zonesCount: number;
  loadingCrops: boolean;
  loadingZones: boolean;
  actions?: React.ReactElement[];
}

const HeaderContent = ({
  area,
  varietyName,
  cropName,
  zonesCount,
  actions = [],
  loadingCrops,
  loadingZones,
}: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FieldDetails
        area={area}
        cropName={cropName}
        varietyName={varietyName}
        zonesCount={zonesCount}
        loadingCrops={loadingCrops}
        loadingZones={loadingZones}
      />
      {actions.length > 0 && (
        <ul className={classes.actionList}>
          {actions.map(action => (
            <li>{React.cloneElement(action)}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default HeaderContent;
