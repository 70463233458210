import React from 'react';
import { P } from '@hummingbirdtechgroup/crips-ui';
import { CropData } from 'services/useFieldCrop/useFieldCrop';

const getCropName = ({ cropName, varietyName }: CropData) =>
  `${cropName || 'Unspecified'} / ${varietyName || 'Unknown'}`;

function CropName({
  classes,
  cropData,
}: {
  classes: string;
  cropData?: CropData;
}) {
  return <P className={classes}>{getCropName(cropData || {})}</P>;
}

export default CropName;
