import React, { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { useRouteMatch } from 'react-router-dom';
import { useAuth } from '@hummingbirdtechgroup/wings-auth';
import {
  Colours,
  GlobalLayout,
  MediaQueries,
} from '@hummingbirdtechgroup/crips-ui';
import { useSearchParams } from 'services';
import { useGoogleAnalyticsEvent } from 'libraries/googleAnalytics';
import useNavOptions from './useNavOptions';

type Props = {
  children: ReactNode;
  hasHeader?: boolean;
  className?: string;
};

const useStyles = createUseStyles(() => ({
  root: {
    zIndex: 9999,
  },
  pageContent: {
    backgroundColor: Colours.septernary,
    [MediaQueries.medium]: {
      position: 'relative',
      height: '100%',
    },
  },
  hasHeader: {
    [MediaQueries.medium]: {
      paddingTop: '6.4rem',
    },
  },
}));

export default ({ children, hasHeader = false, className }: Props) => {
  const classes = useStyles();
  const { user } = useAuth();

  const { path, params } = useRouteMatch();
  const [urlQueryState] = useSearchParams();
  const fireAnalyticsEvent = useGoogleAnalyticsEvent();

  const optionsList = useNavOptions(
    path,
    params,
    urlQueryState,
    fireAnalyticsEvent,
  );

  return (
    <GlobalLayout
      className={classes.root}
      optionsList={optionsList}
      accountName={user?.name}
    >
      <div
        className={classNames(
          classes.pageContent,
          { [classes.hasHeader]: hasHeader },
          className,
        )}
      >
        {children}
      </div>
    </GlobalLayout>
  );
};
