import React, { useMemo } from 'react';
import { ParentSize } from '@visx/responsive';
import { RangeSlider } from '@hummingbirdtechgroup/crips-ui';
import { createUseStyles } from 'react-jss';
import { DefaultTheme } from 'styles/defaultTheme';
import { SimpleBar } from '../SimpleBar';
import { getColourForValueFromColourList } from '../utils/colors';
import useHistogramContext from './useHistogramContext';
import { NoAnalysisMessage } from '../NoAnalysisMessage';
import { LoadingAnalysisMessage } from '../LoadingAnalysisMessage';

const MAX_HISTOGRAM_VALUE = 255;
const SIMPLIFIER = 5;

function simplifyHistogramPoints(values: number[]): [number, number][] {
  const valuesToCheck = new Set(values);
  if (valuesToCheck.size === 1) return [];

  const tempCopy = values.slice();

  // divide array for chunks of SIMPLIFIER
  const chunks = [];
  while (tempCopy.length > SIMPLIFIER) {
    chunks.push(tempCopy.splice(0, SIMPLIFIER));
  }
  chunks.push(tempCopy);

  // compute average value
  const avgValues = chunks.map(
    arr => arr.reduce((acc, curr) => acc + curr, 0) / arr.length,
  );

  return avgValues.map((val: number, index: number) => [
    (index / MAX_HISTOGRAM_VALUE) * SIMPLIFIER,
    val,
  ]);
}

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    overflow: 'hidden',
  },
  histogramContainer: {
    marginTop: theme.spacing(5),
  },
  rangeSlider: {
    margin: `0 ${theme.spacing(2)}px`,
  },
  message: {
    margin: `${theme.spacing(2)}px 0`,
  },
}));

function NDVIAnalysisPanel(): React.ReactElement | null {
  const classes = useStyles();

  const { data, setMinMax } = useHistogramContext();
  const { min, max, values, colourList } = data;

  const histogramData = useMemo(
    () => (values ? simplifyHistogramPoints(values) : []),
    [values],
  );

  if (data.loading) {
    return <LoadingAnalysisMessage />;
  }

  if (!data.loading && !histogramData.length)
    return <NoAnalysisMessage className={classes.message} />;

  return (
    <div className={classes.root}>
      <ParentSize className={classes.histogramContainer}>
        {({ width }) => (
          <SimpleBar
            expandBars
            height={180}
            width={width as number}
            data={histogramData}
            getBarStyles={barData => ({
              fill: getColourForValueFromColourList(
                barData[0] * MAX_HISTOGRAM_VALUE,
                colourList,
                {
                  lower: min,
                  upper: max,
                },
              ),
            })}
          />
        )}
      </ParentSize>
      <div className={classes.rangeSlider}>
        <RangeSlider
          defaultValue={[min / MAX_HISTOGRAM_VALUE, max / MAX_HISTOGRAM_VALUE]}
          onChange={([mn, mx]) =>
            setMinMax(mn * MAX_HISTOGRAM_VALUE, mx * MAX_HISTOGRAM_VALUE)
          }
        />
      </div>
    </div>
  );
}

export default NDVIAnalysisPanel;
