import { useMemo } from 'react';
import { ApolloError } from '@apollo/client';
import { useFieldCrops } from 'services';
import {
  FieldCrops,
  FieldCrops_baseZones_zoneCroppings,
} from '../../services/useFieldCrops/FieldCrops.gql';

type CropsMap = {
  [key in number]: FieldCrops_baseZones_zoneCroppings[];
};

function createFieldsCropsMap(FieldCropsData?: FieldCrops): CropsMap {
  if (FieldCropsData?.baseZones) {
    return FieldCropsData.baseZones.reduce<{
      [key in string]: FieldCrops_baseZones_zoneCroppings[];
    }>((acc, curr) => {
      if (curr?.fieldId) {
        acc[curr.fieldId] = curr?.zoneCroppings || [];
      }
      return acc;
    }, {});
  }
  return {};
}

function useCropsMap(farmId: string): {
  data: CropsMap;
  loading: boolean;
  error?: ApolloError;
} {
  const { data: fieldCropsData, loading, error } = useFieldCrops(farmId);
  const data = useMemo(
    () => createFieldsCropsMap(fieldCropsData),
    [fieldCropsData],
  );
  return { data, loading, error };
}

export default useCropsMap;
