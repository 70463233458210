import { createUseStyles } from 'react-jss';
import fonts from '@hummingbirdtechgroup/crips-ui/dist/tokens/fonts';
import { Colours, FontWeights } from '@hummingbirdtechgroup/crips-ui';
import { DefaultTheme } from 'styles/defaultTheme';

const useAttributionStyles = createUseStyles((theme: DefaultTheme) => ({
  '@global': {
    '.leaflet-control-attribution': {
      position: 'absolute',
      right: theme.spacing(1),
      bottom: theme.spacing(0.5),
    },
    '.leaflet-container .leaflet-control-attribution': {
      background: 'none',
    },
    '.leaflet-control-attribution .gm-style-cc': {
      width: 93,
    },
    '.leaflet-control-attribution .gmnoprint': {
      width: 'auto',
    },
    '.leaflet-control-attribution .gmnoprint .gm-style-cc': {
      width: 'auto',
      paddingLeft: theme.spacing(1),
    },
    '.leaflet-control-attribution .gm-style-cc div': {
      display: 'none',
      marginRight: -theme.spacing(0.5),
      marginLeft: -theme.spacing(0.5),
    },
    '.leaflet-control-attribution .gm-style-cc span, .leaflet-control-attribution .gm-style-cc a':
      {
        background: 'rgba(255, 255, 255, 0.5)',
        borderRadius: theme.spacing(0.25),
        fontFamily: fonts.roboto,
        fontWeight: FontWeights.Regular,
        color: Colours.primary,
        paddingRight: theme.spacing(0.5),
        paddingLeft: theme.spacing(0.5),
      },
  },
}));

export default useAttributionStyles;
