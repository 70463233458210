export enum SurveyType {
  UAV = 'UAV',
  SAT = 'SAT',
}

type Survey = {
  id: string;
  captured_at: string;
  created_at: string;
  source_type: SurveyType;
  enabled: boolean;
};

export default Survey;
