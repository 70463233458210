import { FeatureCollection } from '@turf/helpers';

export enum AnalysesSlug {
  MEAN_SIZE_FIELD_LEVEL = 'analysis-mean_size',
  MEAN_SIZE_ZONE_LEVEL = 'analysis-mean_size_zone_level',
  PLANT_POPULATION_FIELD_LEVEL = 'analysis-plant_population',
  PLANT_POPULATION_ZONE_LEVEL = 'analysis-plant_population_zone_level',
  PHOTOGRAPHIC = 'analysis-photographic_imagery',
  GRIDDED_NDVI = 'analysis-gridded_ndvi',
}

type CommonMetadata = {
  id: string;
  type: string;
  name: string;
  creation_time: string;
  update_time: string;
};

export type MeanSizeMetadata = Omit<CommonMetadata, 'name'> & {
  name: AnalysesSlug.MEAN_SIZE_FIELD_LEVEL;
  average_cell_area_m_square: number;
  encoded?: 'true' | 'false';
  histogram_bin_step: number;
  histogram_values: number[];
  mean_plant_size: number;
  std_deviation: number;
};

export type MeanSizeZoneMetadata = {
  average_cell_area_m_square: number;
  histogram_values: number[];
  max_bin_value: number;
  mean_plant_size: number;
  name: string;
  std_deviation: number;
};

export type MeanSizeMetadataWithZones = Omit<MeanSizeMetadata, 'name'> & {
  name: AnalysesSlug.MEAN_SIZE_ZONE_LEVEL;
  zones: {
    [zoneId: string]: {
      average_cell_area_m_square: number;
      histogram_values: number[];
      max_bin_value: number;
      mean_plant_size: number;
      name: string;
      std_deviation: number;
      histogram_bin_step: number;
    };
  };
};

export type PlantPopulationMetadata = Omit<CommonMetadata, 'name'> & {
  name: AnalysesSlug.PLANT_POPULATION_FIELD_LEVEL;
  average_cell_area_m_square: number;
  average_plants_per_cell: number;
  // different analyses may have different capitalization for grid size key
  grid_size?: number;
  gridSize?: number;
  max: number;
  min: number;
  total_area_m_square: number;
  total_plants: number;
};

export type PlantPopulationMetadataWithZones = Omit<
  PlantPopulationMetadata,
  'name'
> & {
  name: AnalysesSlug.PLANT_POPULATION_ZONE_LEVEL;
  zones: {
    [zoneId: string]: {
      name: string;
      average_cell_area_m_square: number;
      average_plants_per_cell: number;
      // different analyses may have different capitalization for grid size key
      gridSize?: number;
      grid_size?: number;
      max: number;
      min: number;
      total_area_m_square: number;
      total_plants: number;
    };
  };
};

export type ProductFileData<Metadata> = {
  type: 'COG' | 'HBJSON';
  version: string;
  geojson: FeatureCollection;
  metadata: Metadata;
};

type ProductStatus = 'Pending' | 'Completed' | 'Failed' | 'Inactive';

export type ProductFile = {
  id: string;
  product_id: string;
  data_type: 'Download' | 'COG' | 'HBJSON';
  status: ProductStatus;
  path: string;
  /* URL which can be used for unauthenticated access to the file */
  signed_url: string;
  /* Expiry date of the signed url */
  signed_url_expiry: string;
};

type BaseProduct = {
  id: string;
  product_type_id: string;
  survey_id: string;
  created_at: string;
  updated_at?: string | null;
  /* The list of files belonging to the product | i.e: COG, HBJSON, downloadable reports */
  files: ProductFile[];
  enabled: boolean;
  status: ProductStatus;
  /* The status message */
  message?: string | null;
};

export type Product = BaseProduct & {
  product_type_name:
    | AnalysesSlug.MEAN_SIZE_FIELD_LEVEL
    | AnalysesSlug.PLANT_POPULATION_FIELD_LEVEL
    | AnalysesSlug.PHOTOGRAPHIC
    | AnalysesSlug.GRIDDED_NDVI;
  payload_metadata: MeanSizeMetadata;
};
