import { findIndex, findLastIndex } from 'lodash-es';
import React, { Dispatch, useReducer } from 'react';

export type HistogramData = {
  min: number;
  max: number;
  values?: number[];
  colourList: string[];
  loading: boolean;
};

export type HistogramContextAction =
  | {
      type: 'SET_MIN_MAX';
      data: {
        min: number;
        max: number;
      };
    }
  | {
      type: 'SET_DATA';
      data: number[];
      colourList?: string[];
    }
  | {
      type: 'SET_LOADING';
      loading: boolean;
    };

const colourList = ['#B31C08', '#E6E60B', '#1FB308'];
const defaultValue = { min: 0, max: 255, colourList, loading: false };

export const HistogramContext = React.createContext<
  [HistogramData, Dispatch<HistogramContextAction>]
>([defaultValue, () => null]);
HistogramContext.displayName = 'HistogramContext';

const histogramContextReducer = (
  state: HistogramData,
  action: HistogramContextAction,
): HistogramData => {
  switch (action.type) {
    case 'SET_MIN_MAX': {
      return {
        ...state,
        ...action.data,
      };
    }

    case 'SET_DATA': {
      const values = action.data;
      const minValue = Math.max(...values) * 0.02;
      const min = findIndex(values, value => value > minValue);
      const max = findLastIndex(values, value => value > minValue);

      return {
        ...state,
        min,
        max,
        values,
        loading: false,
        colourList: action.colourList || state.colourList,
      };
    }

    case 'SET_LOADING': {
      return { ...state, loading: action.loading };
    }

    default: {
      return state;
    }
  }
};

export const HistogramProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const [state, dispatch] = useReducer(histogramContextReducer, defaultValue);
  return (
    <HistogramContext.Provider value={[state, dispatch]}>
      {children}
    </HistogramContext.Provider>
  );
};
