import { scale } from 'chroma-js';
import { isNull, isUndefined, isNumber } from 'lodash-es';

const GREY = 'c1c7c9';

type Range = { upper: number; lower: number };

export function generateColourScaleFromColourList(
  colourList: string[],
  range = { upper: 1, lower: 0 },
  scaleLength = 1,
) {
  return scale(colourList)
    .domain([range.lower, range.upper])
    .classes(scaleLength);
}

export function getColourForValueFromColourList(
  value: number,
  colourList: string[],
  range: Range,
) {
  return isNull(value) || isUndefined(value) || !isNumber(value)
    ? GREY
    : generateColourScaleFromColourList(colourList, range)(value).hex();
}
