import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { ApplicationSummary } from './ApplicationSummary';

const ApplicationsRouter = ({ match }: RouteComponentProps) => (
  <Switch>
    <Route
      path={`${match.url}/:applicationId/summary`}
      component={ApplicationSummary}
    />
    <Route
      path={`${match.url}/:applicationId/edit`}
      component={() => <div>edit</div>}
    />
    <Route
      path={`${match.url}/:applicationId/bands`}
      component={() => <div>bands</div>}
    />
  </Switch>
);

export default ApplicationsRouter;
