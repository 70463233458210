import {
  useQuery,
  gql,
  QueryResult,
  QueryFunctionOptions,
} from '@apollo/client';
import { MapFields, MapFieldsVariables } from './MapFields.gql';

export const MAP_FIELDS = gql`
  query MapFields($farmId: Int) {
    mapFields(farmId: $farmId) {
      name
      id
      baseZoneId
      baseZoneArea
      baseZoneGeometry
    }
  }
`;

export default function useMapFields(
  farmId?: number,
): QueryResult<MapFields, MapFieldsVariables> {
  const options: QueryFunctionOptions = {
    skip: !farmId,
    variables: {
      farmId,
    },
    fetchPolicy: 'cache-first',
  };
  return useQuery<MapFields, MapFieldsVariables>(MAP_FIELDS, options);
}
