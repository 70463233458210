import React from 'react';
import { Select, SortIcon } from '@hummingbirdtechgroup/crips-ui';
import { useSearchParams } from 'services';
import { createUseStyles } from 'react-jss';
import { DefaultTheme } from '../../styles/defaultTheme';

type Props = {
  className?: string;
  disabled?: boolean;
};

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  icon: {
    height: theme.spacing(3),
    float: 'left',
  },
}));

export const SORT_BY_NAME = 'name';
export const SORT_BY_LATEST_FLIGHT = 'latestFlight';
export const SORT_BY_DEFAULT = SORT_BY_LATEST_FLIGHT;

const SEARCH_OPTIONS = [
  {
    value: SORT_BY_NAME,
    label: 'Name',
  },
  {
    value: SORT_BY_LATEST_FLIGHT,
    label: 'Latest flight',
  },
];

const SortSelect = ({ className, disabled }: Props) => {
  const [urlQueryState, setQueryParams] = useSearchParams();

  const onChange = (event: React.MouseEvent<HTMLInputElement>) => {
    setQueryParams({ ...urlQueryState, sort: event.currentTarget.value });
  };

  const classes = useStyles();

  const { sort = SORT_BY_DEFAULT } = urlQueryState;

  return (
    <Select
      className={className}
      labelText={
        <>
          <SortIcon className={classes.icon} />
          Sort by
        </>
      }
      options={SEARCH_OPTIONS}
      defaultValue={sort}
      onChange={onChange}
      disabled={disabled || SEARCH_OPTIONS.length === 1}
    />
  );
};

export default SortSelect;
