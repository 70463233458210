import React from 'react';
import { createUseStyles } from 'react-jss';
import { RouteComponentProps } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '@hummingbirdtechgroup/crips-ui';
import { useApplication, useField, useFieldCrop } from 'services';
import defaultTheme, { DefaultTheme } from 'styles/defaultTheme';
import {
  BackLink,
  Page,
  PageHeader,
  ToolBox,
  ToolsPanel,
} from 'components/Layout';
import FreshMap from 'components/FreshMap';
import { HeaderContent, LoadingMessage } from 'components';
import ApplicationDetails from './ApplicationDetails';
import ApplicationBands from './ApplicationBands';

const useStyles = createUseStyles(({ spacing }: DefaultTheme) => ({
  root: {
    backgroundColor: '#f7f9fd',
    height: '100%',
  },
  contentWrapper: {
    position: 'relative',
    height: '100%',
  },
  mapContainer: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
  },
  details: {
    marginTop: spacing(2),
  },
}));

const BAPTON_BOUNDS = [
  { lat: 51.146705, lng: -2.0574259757996 },
  { lat: 51.087364274386, lng: -1.990256141320651 },
];

function ApplicationSummary({
  match,
}: RouteComponentProps<{ applicationId: string }>) {
  const classes = useStyles();
  const isDesktop = useMediaQuery({ minWidth: Breakpoints.medium });

  const {
    params: { applicationId },
  } = match;

  const { data: application, product } = useApplication(
    applicationId,
    !applicationId,
  );

  const fieldId = product?.survey.field_id;
  const { data: field, loading } = useField(fieldId);
  const baseZone = field?.field?.baseZones?.[0];
  const farmId = field?.field?.farm?.id;
  const { data: cropData, loading: loadingCrops } = useFieldCrop(fieldId);

  return (
    <Page hasHeader>
      <PageHeader
        pageTitle={
          field?.field?.name ? (
            `Field: ${field.field.name}`
          ) : (
            <LoadingMessage>Loading field...</LoadingMessage>
          )
        }
        backLink={<BackLink to={`/farm/${farmId}`} />}
      >
        <HeaderContent
          area={baseZone?.area}
          cropName={cropData?.cropName}
          varietyName={cropData?.varietyName}
          zonesCount={field?.field?.zones?.length || 0}
          loadingZones={loading}
          loadingCrops={loadingCrops}
        />
      </PageHeader>
      <section className={classes.contentWrapper}>
        {isDesktop && (
          <div className={classes.mapContainer}>
            <FreshMap
              bounds={BAPTON_BOUNDS as any}
              boundsOptions={{ paddingTopLeft: [defaultTheme.spacing(70), 0] }}
            />
          </div>
        )}
        <ToolsPanel>
          <ToolBox>
            <ApplicationBands data={application} />
            <ApplicationDetails
              data={application}
              className={classes.details}
            />
          </ToolBox>
        </ToolsPanel>
      </section>
    </Page>
  );
}

export default ApplicationSummary;
