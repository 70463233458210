import { useQuery } from 'react-query';
import { get } from '@hummingbirdtechgroup/wings-request';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { UseQueryOptions } from 'react-query/types/react/types';
import { Product } from './product';

const useProductQuery = (
  id?: string,
  options?: UseQueryOptions<Product, Error, Product, (string | undefined)[]>,
) => {
  const config = useConfig();

  return useQuery(
    ['product', id],
    () => get<Product>(config.apiUrl, `/v1/products/${id}`),
    options,
  );
};
export default useProductQuery;
