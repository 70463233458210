import React, { CSSProperties, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { Colours, FontWeights } from '@hummingbirdtechgroup/crips-ui';
import { DefaultTheme } from '../../styles/defaultTheme';

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    position: 'relative',
  },
  iconWrapper: {
    display: 'flex',
    background: 'transparent',
    transition: '.5s',
    '&:hover': { background: Colours.quarternary },
  },
  messagePanel: {
    position: 'absolute',
    fontSize: '1.2rem',
    lineHeight: '1.6rem',
    fontWeight: FontWeights.Regular,
    backgroundColor: Colours.quarternary,
    padding: [
      theme.spacing(0.5),
      theme.spacing(0.5),
      theme.spacing(0.25),
      theme.spacing(0.5),
    ],
    opacity: 0,
    transition: 'all 0.3s',
    borderRadius: theme.spacing(0.25),
  },
  messagePanelHovered: {
    opacity: 1,
    transition: 'all 0.3s',
  },
  right: {
    top: '50%',
    right: 0,
    transform: 'translate(calc(100% + 4px),-50%)',
  },
  bottom: {
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, calc(100% + 4px))',
  },
  bottomLeft: {
    bottom: 0,
    transform: 'translate(0, calc(100% + 4px))',
  },
}));

type Props = {
  className?: string;
  children: React.ReactNode;
  message: string | React.ReactNode;
  styles?: CSSProperties;
  position?: 'right' | 'bottom' | 'bottomLeft';
};

function Tooltip({
  className,
  children,
  message,
  styles,
  position = 'bottom',
}: Props) {
  const classes = useStyles();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isTransition, setIsTransition] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (isTransition && tooltipOpen) {
        setTooltipOpen(false);
        setIsTransition(false);
      }
    }, 300);
  }, [isTransition]);

  return (
    <div className={classNames(classes.root, className)}>
      <button
        className={classes.iconWrapper}
        onMouseLeave={() => setIsTransition(true)}
        onMouseEnter={() => {
          setTooltipOpen(true);
          setIsTransition(false);
        }}
      >
        {children}
      </button>
      <p
        onMouseLeave={() => setIsTransition(true)}
        onMouseEnter={() => setIsTransition(false)}
        className={classNames(
          classes.messagePanel,
          {
            [classes.messagePanelHovered]: tooltipOpen,
          },
          classes[position],
        )}
        style={{ ...styles }}
      >
        {message}
      </p>
    </div>
  );
}

export default Tooltip;
