import { onError } from '@apollo/client/link/error';

/**
 * Logs any error to Sentry setting relevant details to the scope.
 * If it is a graphql error it will include additional error messages.
 *
 * Console logs are included to improve debugging during development.
 *
 * The requestId is always passed to the server in headers for matching of actual
 * queries in the server logs. In future we could log server errors to sentry too and match them
 * with this id.
 */
export const errorHandler = onError(
  ({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.error(
          `%c [GraphQL error]: On operation ${operation.operationName}: Message: ${message}`,
          'color: orange',
          { locations, path },
        ),
      );
    }

    if (networkError) {
      console.error(
        `%c [Network error]: On operation ${operation.operationName}: ${networkError}`,
        'color: red',
      );
    }
  },
);
