import { BackArrowIcon } from '@hummingbirdtechgroup/crips-ui';
import { LocationDescriptor } from 'history';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { Tooltip } from 'components';

const useStyles = createUseStyles({
  backButton: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  message: {
    whiteSpace: 'nowrap',
  },
});

type Props = {
  tooltipInfo?: string;
  to: LocationDescriptor<unknown>;
};

export default function BackLink({ tooltipInfo, to }: Props) {
  const classes = useStyles();
  return (
    <Link to={to}>
      <Tooltip
        message={
          <span className={classes.message}>{tooltipInfo || 'Go Back'}</span>
        }
        position="bottomLeft"
      >
        <BackArrowIcon className={classes.backButton} />
      </Tooltip>
    </Link>
  );
}
