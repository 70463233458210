import React, { ReactElement } from 'react';
import { get } from 'lodash-es';
import * as L from 'leaflet';
import { Feature, GeometryObject } from '@turf/helpers';
import {
  getSymbol,
  getValue,
  useUnitSystem,
} from '@hummingbirdtechgroup/wings-unit-conversion';
import useAnalysisMeanSizeData from 'services/useAnalysisMeanSizeData/useAnalysisMeanSizeData';
import ProgressiveGeoJSONRenderer from 'components/FreshMap/ProgressiveGeoJSONRenderer';
import { useGoogleAnalyticsEvent } from 'libraries/googleAnalytics';
import { EventCategory } from 'constants/eventCategory';
import { EventAction } from 'constants/eventAction';
import { getColourByPercentileRange } from '../utils/colourScale.utility';
import usePercentileFeaturesRange from './usePercentileFeaturesRange';
import { PLANT_MEAN_SIZE_ANALYSIS_COLOURS } from './PlantMeanSizeAnalysisColours';

type Props = {
  surveyId: string;
  zoneId?: string;
};

function PlantMeanSizeAnalysisMap({ surveyId, zoneId }: Props): ReactElement {
  const { data } = useAnalysisMeanSizeData(surveyId, zoneId);
  const features = data?.geojson?.features || [];
  const percentileRange = usePercentileFeaturesRange(features);
  const unitSystem = useUnitSystem();
  const fireAnalyticsEvent = useGoogleAnalyticsEvent();

  const getContent = (feature: Feature<GeometryObject, any>) => {
    const value = feature?.properties?.value ?? 0;
    const convertedValue = getValue(value, 'centimetres-square', unitSystem);
    const symbol = getSymbol('centimetres-square', unitSystem);
    return `Mean size ${convertedValue} ${symbol}`;
  };

  return (
    <>
      {!!data?.geojson.features.length && (
        <ProgressiveGeoJSONRenderer
          key={zoneId}
          pathOptions={{ renderer: L.canvas() }}
          data={data.geojson}
          style={feature => ({
            weight: 1,
            fillOpacity: 1,
            color: getColourByPercentileRange(
              percentileRange,
              PLANT_MEAN_SIZE_ANALYSIS_COLOURS,
              get(feature, 'properties.value', 0),
            ),
          })}
          onEachFeature={(feature, layer) => {
            layer.bindTooltip(getContent(feature), {
              direction: 'top',
              sticky: true,
            });
            layer.on('tooltipopen', () => {
              fireAnalyticsEvent(
                EventCategory.ANALYSIS,
                EventAction.MAP_INTERACTION,
                'Hovering',
              );
            });
          }}
        />
      )}
    </>
  );
}

export default PlantMeanSizeAnalysisMap;
