/* eslint-disable no-underscore-dangle */
import * as L from 'leaflet';
import type {
  DoneCallback,
  Coords,
  GridLayerOptions,
  TileLayerOptions,
} from 'leaflet';
import { filter } from 'lodash-es';
import { scale } from 'chroma-js';

// note: Tile is taken from leaflets `InternalTiles` type and should not be modified.  - SFR 2021-01-19
export type Tile = {
  active?: boolean;
  coords: Coords;
  current: boolean;
  el: HTMLCanvasElement;
  loaded?: Date;
  retain?: boolean;
  originalImageData?: any;
};

function generateGrayScaleToRGBMap(
  min = 0,
  max = 255,
  mapLength = 255,
  colorRamp = ['#B31C08', '#E6E60B', '#1FB308'],
) {
  const colourMap = [];
  const colourScale = scale(colorRamp).domain([min, max]);

  for (let i = 0; i <= mapLength; i += 1) {
    colourMap.push(colourScale(i).rgb());
  }

  return colourMap;
}

export interface ColourisedTileOptions
  extends GridLayerOptions,
    TileLayerOptions {
  /** url path to the map tiles */
  path: string;
  /** Array of hex values to turn into the color scale for the tile */
  colourRamp?: string[];
  /** A callback that gets passed the tile values as a parameter */
  getTileValues?: (values: number[]) => void;
  min?: number;
  max?: number;
}

const ColourisedTiles = L.GridLayer.extend({
  // default options
  options: {
    // set maxNativeZoom to allow digital zoom past 20. This is for better visibility of gap lines
    maxNativeZoom: 20,
  },

  initialize(options: ColourisedTileOptions) {
    L.Util.setOptions(this, options);
    this.initHistogram();
  },

  createImage() {
    return new Image();
  },

  _histogram: null,

  initHistogram() {
    this._histogram = new Array(256).fill(0);
  },

  getHistogram() {
    return this._histogram;
  },

  setMinMax(min: number, max: number) {
    this._min = min;
    this._max = max;
  },

  initialiseCanvas(
    img: CanvasImageSource,
    coords: Coords,
    tile: HTMLCanvasElement,
    done: DoneCallback,
  ) {
    const key = this._tileCoordsToKey(coords);
    const context: CanvasRenderingContext2D | null = tile.getContext('2d');

    if (!context) {
      done(Error('Could not find a Canvas Context'));
      return;
    }

    context.drawImage(img, 0, 0);

    const originalImageData = context.getImageData(0, 0, 256, 256);

    if (this._tiles[key]) {
      this._tiles[key].originalImageData = originalImageData;
    }

    this.colourTile(
      this._min || this.options.min,
      this._max || this.options.max,
      { el: tile, originalImageData },
      context,
      true,
    );

    done(undefined, tile);
  },

  createTile(coords: Coords, done: DoneCallback) {
    // create a <canvas> element for drawing
    const tile = L.DomUtil.create('canvas', 'leaflet-tile');

    // setup tile width and height according to the options
    const tileSize = this.getTileSize();
    tile.setAttribute('width', tileSize.x);
    tile.setAttribute('height', tileSize.y);

    const img = this.createImage();
    // invert Y axis for tms tile format
    const invertedY = this._globalTileRange.max.y - coords.y;

    img.crossOrigin = 'Anonymous';
    img.src = `${this.options.path}/${coords.z}/${coords.x}/${invertedY}.png`;

    img.onload = this.initialiseCanvas.bind(this, img, coords, tile, done);
    img.onerror = done;

    return tile;
  },

  colourTile(
    min: number,
    max: number,
    tile: Tile,
    context: CanvasRenderingContext2D,
    createHistogram = false,
  ) {
    const imageData = context.getImageData(0, 0, 256, 256);
    const { data } = imageData;
    const { originalImageData } = tile;
    const grayScaleToRGBMap = generateGrayScaleToRGBMap(
      min,
      max,
      255,
      this.options.colourRamp || ['#B31C08', '#E6E60B', '#1FB308'],
    );
    for (let i = 0; i < originalImageData.data.length; i += 4) {
      if (data[i + 3] !== 0) {
        const [red, green, blue] = grayScaleToRGBMap[originalImageData.data[i]];

        if (createHistogram && data[i] !== 0) {
          this._histogram[originalImageData.data[i]] += 1;
        }

        data[i] = red;
        data[i + 1] = green;
        data[i + 2] = blue;
      }
    }

    context.putImageData(imageData, 0, 0);
  },

  updateColourMap(min = 0, max = 255) {
    const tiles = this.getActiveTiles();

    this.setMinMax(min, max);
    tiles.forEach((tile: Tile) => {
      const context = tile.el.getContext('2d');
      this.colourTile(min, max, tile, context);
    });
  },

  getTiles() {
    return this._tiles;
  },

  getActiveTiles() {
    const tiles = this.getTiles();

    return filter(tiles, tile => tile.originalImageData !== undefined);
  },
});

export function colourisedTiles(options: ColourisedTileOptions): any {
  // @ts-ignore - Could not figure out how to make this constructor take the options - SFR 2021/01/26
  return new ColourisedTiles(options);
}
