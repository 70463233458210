import { scale } from 'chroma-js';
import { isNull, isUndefined, isNumber } from 'lodash-es';

const CLOUDY_GREY = '#767D7F';
const ZERO_RATE_ZONE = '#D3D3D3';
const LIGHT_BLUE = '#2093ce';

function generateColourScaleFromColourList(
  colourList: string[],
  range = { upper: 1, lower: 0 },
  scaleLength = 1,
) {
  return scale(colourList)
    .domain([range.lower, range.upper])
    .classes(scaleLength);
}

function getColourForValueFromColourList(
  value: number,
  colourList: string[],
  range = { upper: 1, lower: 0 },
) {
  return isNull(value) || isUndefined(value) || !isNumber(value)
    ? LIGHT_BLUE
    : generateColourScaleFromColourList(colourList, range)(value).hex();
}

export function generateBandColor(
  rate: number,
  colourList: string[],
  isSelected: boolean,
  maxRate: number,
  minRate = 0,
) {
  const isNoValueCloudy = rate === 0;

  let color;
  if (rate === 0) {
    color = isNoValueCloudy ? CLOUDY_GREY : ZERO_RATE_ZONE;
  } else {
    color = getColourForValueFromColourList(rate, colourList, {
      upper: maxRate,
      lower: minRate,
    });
  }

  return {
    color: isSelected ? LIGHT_BLUE : color,
    fillColor: isSelected ? ZERO_RATE_ZONE : color,
    fillOpacity: 0.9,
    fillRule: 'nonzero',
    weight: isSelected ? 3 : 1,
  };
}
