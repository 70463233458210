const defaultSpacing = 8;

const defaultTheme = {
  palette: {
    // primary: {
    //   main: '#2093ce',
    //   light: '#edfcff',
    //   disabled: '#2093ce40',
    // },
    secondary: {
      // --hb-darkblue
      dark: '#00659d',
      // --hb-blue --c-body-bg
      main: '#2093ce',
      // --hb-creamy-blue
      light: '#66c3ff',
    },
    warning: {
      // --c-warning
      main: `#ec9d56`,
      light: `rgba(255,177,0,0.051)`,
    },
    info: {
      // --c-info
      main: '#d8e9ed',
    },
    success: {
      // --c-success
      main: '#4f9053',
      light: 'rgba(0,175,0,0.051)',
    },
    danger: {
      // --c-success
      main: '#E0032A',
      light: 'rgba(224, 3, 42, 0.051)',
    },
    text: {
      // --c-text-secondary
      secondary: '#7a8587',
      // --c-text-minor
    },
    background: {
      // --c-content-bg
      default: 'white',
    },
    grey: {},
    action: {
      // --c-selected
      selected: '#aaeeff',
      disabled: '#2093ce40',
      hover: '',
    },
    switchBackground: {
      default: '#505271',
    },
  },

  zIndex: {
    tooltip: 1100,
  },
  typography: {
    fontFamily: '"Roboto", "Arial", sans-serif',
    fontSizeSmall: 14,
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  spacing: (multiplier: number) =>
    multiplier > 0 ? defaultSpacing * multiplier : 0,
};

export type DefaultTheme = typeof defaultTheme;

export default defaultTheme;
// # sourceMappingURL=default-theme.js.map
