import React, { useState } from 'react';
import { GeoJSON } from 'react-leaflet';
import { useHistory } from 'react-router-dom';
import BoundaryTooltip from './BoundaryTooltip';
import { Field } from './useFields';

type Props = {
  farmId: string;
  field: Field;
};

const FIELD_BOUNDARY_COLOUR = '#13D8D8';

const FieldBoundary = ({ farmId, field }: Props) => {
  const [isHover, setIsHover] = useState(false);

  const { id, geometry } = field;

  const history = useHistory();

  const onClick = () => {
    history.push(`/farm/${farmId}/field/${id}`);
  };

  return (
    <GeoJSON
      style={{
        color: FIELD_BOUNDARY_COLOUR,
        opacity: isHover ? 1 : 0.85,
        fillColor: FIELD_BOUNDARY_COLOUR,
        fillOpacity: isHover ? 0.5 : 0.25,
        weight: 2,
      }}
      eventHandlers={{
        click: onClick,
        mouseover: () => setIsHover(true),
        mouseout: () => setIsHover(false),
      }}
      data={JSON.parse(geometry)}
    >
      <BoundaryTooltip field={field} />
    </GeoJSON>
  );
};

export default FieldBoundary;
