import React from 'react';
import { createUseStyles } from 'react-jss';
import { DefaultTheme } from 'styles/defaultTheme';

const useStyles = createUseStyles(({ spacing }: DefaultTheme) => ({
  root: {
    margin: `${spacing(1)}px 0`,
    display: 'flex',
    alignItems: 'space-between',
    justifyContent: 'space-between',
  },
}));

export default ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};
