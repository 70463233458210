import snakeize from 'snakeize';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RestLink } from 'apollo-link-rest';
import { v4 as uuid } from 'uuid';
import { authHandler } from './authHandler';
import { errorHandler } from './errorHandler';

const cache = new InMemoryCache();

const setRequestIdLink = setContext((_, prevContext) => ({
  ...prevContext,
  headers: {
    ...prevContext?.headers,
    // a unique request ID for debugging: this is logged on the server for each request
    'X-Request-ID': uuid(),
  },
}));

const restLink = (apiUrl: string) =>
  new RestLink({
    uri: apiUrl,
    fieldNameNormalizer: (field: unknown) => snakeize(field),
  });

const customFetch = (uri: string, options: any) => {
  const { operationName } = JSON.parse(options.body);
  return fetch(`${uri}?operation_name=${operationName}`, options);
};

const httpLink = (url: string) =>
  new HttpLink({
    uri: `${url}/v1/graphql`,
    fetch: customFetch,
  });

const gqlClient = (apiUrl: string) =>
  new ApolloClient({
    link: ApolloLink.from([
      authHandler,
      restLink(apiUrl),
      setRequestIdLink,
      errorHandler,
      httpLink(apiUrl),
    ]),
    cache,
    queryDeduplication: true,
    name: 'webapp-fresh',
    version: process.env.APP_VERSION,
  });

export default gqlClient;
