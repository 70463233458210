import { useQueries, UseQueryResult } from 'react-query';
import { chunk } from 'lodash-es';
import { format } from 'date-fns';
import { get } from '@hummingbirdtechgroup/wings-request';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { Benchmark } from '../../types/Benchmark';

export const YYYY_MM_DD = 'yyyy-MM-dd';

type UseBenchmarksResult = {
  data: [] | Benchmark[];
  isLoading: boolean;
};

const getFieldParams = (fieldIds: number[]) =>
  fieldIds.map(fieldId => `&fields[]=${fieldId}`).join('');

const combineQueryData = (queries: UseQueryResult[]) =>
  queries.reduce((accumulatedData: any[], query: any) => {
    const benchmarks = query?.data?.benchmarks || [];
    return [...accumulatedData, ...benchmarks];
  }, []);

const useBenchmarks = (fieldIds: number[]): UseBenchmarksResult => {
  const config = useConfig();

  const fieldIdChunks = chunk(fieldIds, 100);

  const dateFrom = format(
    new Date(new Date().getTime() - 24 * 60 * 60 * 1000 * 30),
    YYYY_MM_DD,
  );
  const dateTo = format(new Date(), YYYY_MM_DD);

  const queries = useQueries(
    fieldIdChunks.map((fieldIdChunk: number[]) => ({
      queryKey: ['benchmarks', `fields-${fieldIdChunk.join('-')}`],
      queryFn: () =>
        get(
          config.apiUrl,
          `/v1/benchmarks/?product_types[]=analysis-plant_population&product_types[]=analysis-mean_size&date_from=${dateFrom}&date_to=${dateTo}&${getFieldParams(
            fieldIdChunk,
          )}`,
        ),
      options: { enabled: fieldIds.length !== 0 },
    })),
  );

  const isLoading = !!queries.find(query => query.isLoading);

  return {
    data: isLoading ? [] : combineQueryData(queries),
    isLoading,
  };
};

export default useBenchmarks;
