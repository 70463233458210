import React, { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { DefaultTheme } from 'styles/defaultTheme';
import { MediaQueries } from '@hummingbirdtechgroup/crips-ui';

const useStyles = createUseStyles(({ spacing, palette }: DefaultTheme) => ({
  root: {
    backgroundColor: palette.background.default,
    padding: spacing(2),
    borderRadius: 4,
    marginTop: spacing(2),
    '&:first-child': {
      marginTop: 0,
    },
    [MediaQueries.medium]: {
      '&:first-child': {
        marginTop: spacing(2),
      },
    },
  },
}));

type Props = {
  children: ReactNode;
  className?: string;
};

const ToolBox = ({ children, className }: Props) => {
  const classes = useStyles();
  return <div className={classNames(classes.root, className)}>{children}</div>;
};

export default ToolBox;
