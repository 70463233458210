import { User } from '@hummingbirdtechgroup/wings-auth/dist/types';
import { UserFarms_farms } from 'services/useFarmsForUser/UserFarms.gql';
import { MapFields_mapFields } from 'services/useMapFields/MapFields.gql';

export type FlightRequestMessage = {
  user: User;
  farm?: UserFarms_farms | null;
  fields: MapFields_mapFields[];
  dates: [string, string];
  additionalInfo: string;
};

const createFightRequestSlackMessage = ({
  user,
  farm,
  fields,
  dates,
  additionalInfo,
}: FlightRequestMessage) => {
  const [startDate, endDate] = dates;
  const slackMessage = {
    attachments: [
      {
        color: '#F9DB36',
        blocks: [
          {
            type: 'header',
            text: {
              type: 'plain_text',
              text: `:bell: ${farm?.name} flight requested in range between: ${startDate} - ${endDate}`,
            },
          },
          {
            type: 'context',
            elements: [
              {
                type: 'plain_text',
                text: 'Dates:',
              },
              {
                type: 'plain_text',
                text: startDate,
              },
              {
                type: 'plain_text',
                text: endDate,
              },
            ],
          },
          {
            type: 'section',
            text: {
              type: 'plain_text',
              text: `Farm Name: ${farm?.name}`,
            },
          },
          {
            type: 'section',
            text: {
              type: 'plain_text',
              text: `Customer: ${user.name}`,
            },
          },
          {
            type: 'section',
            text: {
              type: 'plain_text',
              text: `Comment: ${additionalInfo || '-//-'}`,
            },
          },
          {
            type: 'divider',
          },
          {
            type: 'header',
            text: {
              type: 'plain_text',
              text: 'Selected fields:',
            },
          },
          ...fields.map(field => ({
            type: 'context',
            elements: [
              {
                type: 'plain_text',
                text: `Field name: ${field.name}`,
              },
              {
                type: 'plain_text',
                text: `Field id: ${field.id}`,
              },
              {
                type: 'mrkdwn',
                text: `https://app.hummingbirdtech.com/map/${farm?.id}/field/${field.id}`,
              },
            ],
          })),
        ],
      },
    ],
  };

  return slackMessage;
};

export default createFightRequestSlackMessage;
