import { useMemo } from 'react';
import useMapFields from 'services/useMapFields/useMapFields';
import { MapFields } from 'services/useMapFields/MapFields.gql';
import useCropsMap from './useCropsMap';
import useZonesMap from './useZonesMap';
import useBenchmarkStatistics from './useBenchmarkStatistics';

export type Survey = {
  id: string;
  capturedAt: Date;
};

export type Field = {
  id: number;
  name: string;
  geometry: any;
  area: number;
  varietyName: string;
  cropName: string;
  zonesData: unknown[];
  latestFlightDate: Date;
  count?: number;
  meanSize?: number;
};

export type FieldsResponse = {
  loadingCrops: boolean;
  loadingZones: boolean;
  loadingFlightDates: boolean;
  loading: boolean;
  isError: boolean;
  data: Field[];
};

const getFieldIds = (mapFields: MapFields) =>
  mapFields.mapFields?.map(mapField => Number(mapField?.id));

export default function useFields(farmId: string): FieldsResponse {
  const { data: cropsMap, loading: loadingCrops } = useCropsMap(farmId);
  const { data: zonesMap, loading: loadingZones } = useZonesMap(farmId);
  const { data: mapFields, loading, error } = useMapFields(Number(farmId));
  const fieldIds = loading || !mapFields ? [] : getFieldIds(mapFields);
  const { data: benchmarkStatistics, loading: loadingFlightDates } =
    useBenchmarkStatistics(fieldIds);

  const isError = !!error;

  const fields = useMemo(() => {
    if (mapFields?.mapFields) {
      return mapFields.mapFields.map<Field>(
        // @ts-ignore
        ({ name, id, baseZoneArea, baseZoneGeometry }) => {
          let varietyName = '';
          let cropName = '';
          if (cropsMap[id]?.length === 1) {
            varietyName = cropsMap[id][0].variety?.name || '';
            cropName = cropsMap[id][0].variety?.crop?.name || '';
          } else if (cropsMap[id]?.length > 1) {
            cropName = 'Mixed';
            varietyName = 'mixed';
          }

          const myBenchmarkStatistic = benchmarkStatistics.find(
            benchmarkStatistic => benchmarkStatistic.fieldId === Number(id),
          );

          return {
            id,
            name,
            geometry: baseZoneGeometry,
            area: baseZoneArea,
            varietyName,
            cropName,
            zonesData: zonesMap[id] || [],
            latestFlightDate:
              myBenchmarkStatistic?.surveyCapturedAt ?? new Date(0),
            count: myBenchmarkStatistic?.count,
            meanSize: myBenchmarkStatistic?.meanSize,
          };
        },
      );
    }
    return [];
  }, [mapFields?.mapFields, cropsMap, zonesMap, benchmarkStatistics]);

  return {
    loadingCrops,
    loadingZones,
    loadingFlightDates,
    loading,
    isError,
    data: fields,
  };
}
