import {
  useQuery,
  gql,
  QueryFunctionOptions,
  QueryResult,
} from '@apollo/client';
import { Variety, VarietyVariables } from './Variety.gql';

const VARIETY = gql`
  query Variety($fieldId: ID!) {
    field(id: $fieldId) {
      baseZones {
        zoneCroppings {
          variety {
            id
            name
            crop {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export type CropData = {
  cropId?: string;
  cropName?: string;
  varietyId?: string;
  varietyName?: string;
};

type UseFieldCropResult = Omit<
  QueryResult<Variety, VarietyVariables>,
  'data'
> & {
  data?: CropData;
};

export default function useFieldCrop(fieldId = ''): UseFieldCropResult {
  const options: QueryFunctionOptions<Variety, VarietyVariables> = {
    skip: !fieldId || !fieldId,
    variables: {
      fieldId,
    },
  };
  const result = useQuery<Variety, VarietyVariables>(VARIETY, options);
  const data = result?.data;
  let cropData: CropData | undefined;
  if (data?.field?.baseZones?.[0]?.zoneCroppings?.[0]?.variety) {
    const { variety } = data?.field?.baseZones?.[0]?.zoneCroppings?.[0];
    cropData = {
      cropId: variety?.crop?.id,
      cropName: variety?.crop?.name,
      varietyId: variety?.id,
      varietyName: variety?.name,
    };
  }
  return { ...result, data: cropData };
}
