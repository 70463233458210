import React, { useEffect } from 'react';
import { LatLngBounds } from 'leaflet';
import ColourisedTileLayer from '../utils/ColourisedTileLayer';
import useHistogramContext from './useHistogramContext';

export type NDVIValues = {
  values: number[];
  range: [number, number];
};

type Props = {
  /** Absolute height required to display a leaflet map */
  bounds?: LatLngBounds;
  /** url path for the map tiles src */
  path: string;
  /** Tile layer opacity */
  opacity?: number;
  /** Array of hex values to turn into the color scale for the tile */
  colourList?: string[];
};

/**
 * For displaying a tile preview.
 * Static map which fetches tiles and displays them for the given field boundary
 */
function NDVITileLayer({
  bounds,
  opacity = 1,
  path,
  colourList = ['#B31C08', '#E6E60B', '#1FB308'],
}: Props): React.ReactElement {
  const { data, setData, setLoading } = useHistogramContext();

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <ColourisedTileLayer
      bounds={bounds}
      opacity={opacity}
      getTileValues={v => setData(v, colourList)}
      min={data?.min}
      max={data?.max}
      path={path}
      colourRamp={colourList}
    />
  );
}

export default NDVITileLayer;
