import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { LogoutRedirect, PrivateRoute } from '@hummingbirdtechgroup/wings-auth';
import { FlightRequest } from 'scenes/FlightRequest';
import { Farm } from 'scenes/Farm';
import { Field } from 'scenes/Field';
import { FieldZone } from 'scenes/FieldZone';
import { ApplicationsRouter } from './scenes/Applications';

function AppRouter(): React.ReactElement {
  return (
    <Switch>
      <Route path="/" exact>
        <Redirect to="/farm" />
      </Route>
      <PrivateRoute
        roles={['SUPERUSER', 'NORMAL_USER']}
        path="/farm"
        exact
        render={() => <Farm />}
      />
      <PrivateRoute
        roles={['SUPERUSER', 'NORMAL_USER']}
        path="/farm/:farmId"
        exact
        render={() => <Farm />}
      />
      <PrivateRoute
        roles={['SUPERUSER', 'NORMAL_USER']}
        path="/farm/:farmId/field/:fieldId"
        exact
        render={() => <Field />}
      />
      <PrivateRoute
        roles={['SUPERUSER', 'NORMAL_USER']}
        path="/farm/:farmId/field/:fieldId/zone/:zoneId"
        exact
        render={() => <FieldZone />}
      />
      <Route path="/logout">
        <LogoutRedirect />
      </Route>
      <Route path="/flight">
        <FlightRequest />
      </Route>
      <Route path="/applications" component={ApplicationsRouter} />
    </Switch>
  );
}

export default AppRouter;
