import React, { useMemo } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { round } from 'lodash-es';
import {
  Litres,
  LitresPerHectare,
} from '@hummingbirdtechgroup/wings-unit-conversion';
import { Application } from 'services/useApplication/useApplication';
import { BoxTitle } from 'components/Layout';
import { DefaultTheme } from 'styles/defaultTheme';
import ApplicationDetailsItem from '../components/ApplicationDetailsItem';

const useStyles = createUseStyles(({ spacing }: DefaultTheme) => ({
  subgroup: {
    border: '1px solid #C1C4D9',
    borderRadius: '4px',
    padding: spacing(2),
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '16px',
    rowGap: '24px',
    flexWrap: 'wrap',
    '& > *': {
      flex: '45%',
    },
  },
}));

function calculateApplicationDetails(application?: Application) {
  if (!application) return null;
  const {
    metadata: {
      max_product_rate: maxProductRate,
      levels,
      max_application_rate: maxApplicationRate,
      number_of_points: numberOfPoints,
    },
  } = application;

  const totalAmountOfProduct = levels
    .map(level => (level.override_value || level.product_rate) * level.hectares)
    .reduce((a, b) => a + b, 0);

  const totalArea = levels
    .map(level => level.hectares)
    .reduce((a, b) => a + b, 0);

  const averageRateOfProduct = totalAmountOfProduct / totalArea;
  const productConcentration = maxApplicationRate / maxProductRate;
  const totalApplicationVolume = totalAmountOfProduct * productConcentration;
  const averageApplicationRate = totalApplicationVolume / totalArea;
  const totalWaterRequirements = totalAmountOfProduct - totalApplicationVolume;

  return {
    numberOfPoints,
    averageRateOfProduct: round(averageRateOfProduct, 2),
    averageApplicationRate: round(averageApplicationRate, 2),
    totalApplicationVolume: round(totalApplicationVolume, 2),
    totalWaterRequirements: round(totalWaterRequirements),
    totalAmountOfProduct: round(totalAmountOfProduct, 2),
  };
}

function ApplicationDetails({
  data,
  className,
}: {
  data?: Application;
  className?: string;
}) {
  const classes = useStyles();
  const details = useMemo(() => calculateApplicationDetails(data), [data]);

  return (
    <section className={classNames(classes.subgroup, className)}>
      <BoxTitle>Application details</BoxTitle>
      <div className={classes.details}>
        <ApplicationDetailsItem
          label="Total amount of N"
          value={<Litres>{details?.totalAmountOfProduct || 0}</Litres>}
        />
        <ApplicationDetailsItem
          label="Total application volume"
          value={<Litres>{details?.totalApplicationVolume || 0}</Litres>}
        />
        <ApplicationDetailsItem
          label="Average rate of N"
          value={
            <LitresPerHectare>
              {details?.averageRateOfProduct || 0}
            </LitresPerHectare>
          }
        />
        <ApplicationDetailsItem
          label="Average application rate"
          value={
            <LitresPerHectare>
              {details?.averageApplicationRate || 0}
            </LitresPerHectare>
          }
        />
        <ApplicationDetailsItem
          label="Total water requirements"
          value={<Litres>{details?.totalWaterRequirements || 0}</Litres>}
        />
        <ApplicationDetailsItem
          label="Number of data points"
          value={(details?.numberOfPoints || 0).toLocaleString()}
        />
      </div>
    </section>
  );
}

export default ApplicationDetails;
