import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import format from 'date-fns/format';
import {
  Alert,
  Breakpoints,
  Dropdown,
  MediaQueries,
  Select,
  Tab,
  TabCase,
  TabList,
  TabPanel,
  TabsContextProvider,
  ZoneIcon,
} from '@hummingbirdtechgroup/crips-ui';
import { createUseStyles } from 'react-jss';
import { GeoJSON, Pane } from 'react-leaflet';
import {
  useParseGeometry,
  useSearchParams,
  useSurveys,
  useSurveyAnalyses,
} from 'services';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import Survey, { SurveyType } from 'services/useSurveys/Survey';
import { Field, Field_field_zones } from 'services/useField/Field.gql';
import defaultTheme, { DefaultTheme } from 'styles/defaultTheme';
import { useGoogleAnalyticsEvent } from 'libraries/googleAnalytics';
import { BoxTitle, ToolBox, ToolsPanel } from 'components/Layout';
import { Product } from 'types/Product';
import { EventCategory } from 'constants/eventCategory';
import { EventAction } from 'constants/eventAction';
import {
  ANALYSIS_PLANT_POPULATION_ZONE_LEVEL,
  ANALYSIS_PLANT_POPULATION_FIELD_LEVEL,
  ANALYSIS_MEAN_SIZE_FIELD_LEVEL,
  ANALYSIS_MEAN_SIZE_ZONE_LEVEL,
  ANALYSIS_PHOTOGRAPHIC_IMAGERY,
  ANALYSIS_GRIDDED_NDVI,
} from 'constants/productSlugs';
import { LoadingMessage } from 'components';
import FreshMap from 'components/FreshMap';
import MapSwitch from 'components/MapSwitch/MapSwitch';
import useZoneOptions from './useZoneOptions';
import ZoneGeoJSON from './components/ZoneGeoJSON';
import { RgbMap, RgbMapDownloadPanel } from './components/RgbMap';
import {
  PlantPopulationAnalysisMap,
  PlantPopulationAnalysisPanel,
} from './components/PlantPopulationAnalysis';
import {
  HistogramProvider,
  NDVIAnalysisPanel,
  NDVITileLayer,
} from './components/NDVI';
import {
  PlantMeanSizeAnalysisMap,
  PlantMeanSizeAnalysisPanel,
} from './components/PlantMeanSizeAnalysis';
import { ExportProductFileControl } from './components/ExportProductFileControl';
import isZoneWithinDate from './isZoneWithinDate';
import { NoAnalysisMessage } from './components/NoAnalysisMessage';

enum Analyses {
  Size,
  Count,
  Imagery,
  NDVI,
}

const useActiveAnalyses = (isZoneSelected: boolean, surveyId?: string) => {
  const { data, isLoading } = useSurveyAnalyses(surveyId);
  const products = data?.products || [];

  const meanSizeSlug = isZoneSelected
    ? ANALYSIS_MEAN_SIZE_ZONE_LEVEL
    : ANALYSIS_MEAN_SIZE_FIELD_LEVEL;
  const plantPopulationSlug = isZoneSelected
    ? ANALYSIS_PLANT_POPULATION_ZONE_LEVEL
    : ANALYSIS_PLANT_POPULATION_FIELD_LEVEL;

  return {
    isLoading,
    data: {
      [Analyses.Size]: !!products.find(
        (product: Product) => product.product_type_name === meanSizeSlug,
      ),
      [Analyses.Count]: !!products.find(
        (product: Product) => product.product_type_name === plantPopulationSlug,
      ),
      [Analyses.Imagery]: !!products.find(
        (product: Product) =>
          product.product_type_name === ANALYSIS_PHOTOGRAPHIC_IMAGERY,
      ),
      [Analyses.NDVI]: !!products.find(
        (product: Product) =>
          product.product_type_name === ANALYSIS_GRIDDED_NDVI,
      ),
    },
  };
};

const useStyles = createUseStyles((theme: DefaultTheme) => {
  const sidebarWidthDesktop = theme.spacing(60);
  return {
    mapContainer: {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    },
    mapActionsContainer: {
      width: `calc(100% - ${sidebarWidthDesktop}px)`,
      position: 'absolute',
      top: theme.spacing(1),
      right: 0,
      justifyContent: 'center',
      display: 'flex',
    },
    mapMessageContainer: {
      width: `calc(100% - ${sidebarWidthDesktop}px)`,
      position: 'absolute',
      top: theme.spacing(6),
      right: 0,
      justifyContent: 'center',
      display: 'flex',
    },
    zoneSwitchLabel: {
      display: 'flex',
      alignItems: 'end',
      gap: theme.spacing(0.5),
      minWidth: theme.spacing(9),
    },
    zoneDropdown: {
      marginTop: theme.spacing(2),
    },
    tabs: {
      marginTop: theme.spacing(1),
    },
    panel: {
      minHeight: theme.spacing(35),
    },
    exportButton: {
      marginTop: theme.spacing(2.5),
      [MediaQueries.large]: {
        marginLeft: theme.spacing(4),
      },
    },
    messageWarning: {
      backgroundColor: '#fdf7ec',
      border: '1px solid #fd9900',
      fontWeight: 'initial',
      padding: 8,
      borderRadius: 8,
    },
    loadingMessage: {
      marginTop: theme.spacing(2),
    },
  };
});

const useSurveyOptions = (surveys: Survey[] = []) =>
  useMemo(
    () =>
      surveys
        .filter(
          survey => survey.enabled && survey.source_type === SurveyType.UAV,
        )
        .map<{ value: string; label: Date }>(survey => ({
          value: survey.id,
          label: new Date(survey.captured_at),
        }))
        .sort((a, b) => b.label.getTime() - a.label.getTime())
        .map(({ value, label }) => ({
          value,
          label: format(label, 'dd MMMM yyyy'),
        })),
    [surveys],
  );

const useZonesDateFilter = (
  zones: Field_field_zones[] = [],
  selectedSurvey?: Survey,
) =>
  useMemo(
    () =>
      selectedSurvey
        ? zones.filter(zone =>
            isZoneWithinDate(zone, new Date(selectedSurvey.captured_at)),
          )
        : // if survey is not selected show only zones without end date
          zones.filter(zone => !zone.endDate),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [zones, selectedSurvey?.captured_at],
  );

export type FieldRouteParams = { fieldId: string; farmId: string };

type Props = {
  containerClassName?: string;
  fieldData?: Field;
  farmId: string;
  fieldId: string;
  zoneId?: string;
  loadingZoneData?: boolean;
};

export default function MapAnalyses({
  containerClassName,
  fieldData,
  farmId,
  fieldId,
  zoneId,
  loadingZoneData = false,
}: Props) {
  const isZoneSelected = Boolean(zoneId);
  const classes = useStyles();
  const [urlQueryState, setQueryParams] = useSearchParams();
  const [isZonesVisible, setIsZonesVisible] = useState(false);
  const history = useHistory();
  const isDesktop = useMediaQuery({ minWidth: Breakpoints.medium });
  const fieldBaseZone = fieldData?.field?.baseZones?.[0];
  const [fieldGeometry, fieldBounds] = useParseGeometry(
    fieldBaseZone?.geometry,
  );
  const surveysResponse = useSurveys(fieldId);
  const currentSurveyId = urlQueryState?.surveyId;
  const selectedSurvey = surveysResponse.data?.surveys.find(
    survey => survey.id === currentSurveyId,
  );

  const { data, isLoading } = useActiveAnalyses(
    isZoneSelected,
    selectedSurvey?.id,
  );

  const setSelectSurvey = (surveyId: string) => {
    setQueryParams({ surveyId });
  };

  const fireAnalyticsEvent = useGoogleAnalyticsEvent();

  const onSurveySelectChange = (event: React.MouseEvent<HTMLInputElement>) => {
    fireAnalyticsEvent(
      EventCategory.NAVIGATION,
      EventAction.BROWSING,
      'Survey',
    );
    setSelectSurvey(event.currentTarget.value);
  };

  const zones = fieldData?.field?.zones;

  const defaultZoneValue = zones?.find(zone => zone.id === zoneId)?.name;

  const surveyZones = useZonesDateFilter(zones ?? undefined, selectedSurvey);

  const zoneOptions = useZoneOptions(
    surveyZones ?? [],
    farmId,
    fieldId,
    fireAnalyticsEvent,
    currentSurveyId,
  );

  const zoneData = zones?.find(zone => zone.id === zoneId);
  const [zoneGeometry, zoneBounds] = useParseGeometry(zoneData?.geometry);

  const config = useConfig();
  const surveyOptions = useSurveyOptions(surveysResponse?.data?.surveys);

  const fireTabAnalyticsEvent = (label: string) =>
    fireAnalyticsEvent(EventCategory.ANALYSIS, EventAction.MAP_BROWSING, label);

  useEffect(() => {
    if (surveyOptions.length && !currentSurveyId) {
      setSelectSurvey(surveyOptions[0].value);
    }
  }, [surveyOptions]);

  const hasAnyAnalysis =
    data[Analyses.Size] ||
    data[Analyses.Count] ||
    data[Analyses.Imagery] ||
    data[Analyses.NDVI];

  return (
    <section className={containerClassName}>
      <TabsContextProvider>
        <HistogramProvider>
          {isDesktop && !!fieldGeometry && (
            <>
              <div className={classes.mapContainer}>
                <FreshMap
                  bounds={isZoneSelected ? zoneBounds : fieldBounds}
                  boundsOptions={{
                    paddingTopLeft: [defaultTheme.spacing(70), 0],
                  }}
                >
                  <GeoJSON data={fieldGeometry} style={{ fill: false }} />

                  {isZoneSelected && zoneGeometry && (
                    <GeoJSON key={zoneId} data={zoneGeometry} />
                  )}
                  {isZonesVisible && (
                    <Pane name="zones">
                      {surveyZones?.map(zone => (
                        <ZoneGeoJSON
                          data={zone.geometry}
                          zoneProps={zone}
                          eventHandlers={{
                            click: () => {
                              history.push(
                                `/farm/${farmId}/field/${fieldId}/zone/${zone.id}`,
                              );
                            },
                          }}
                        />
                      ))}
                    </Pane>
                  )}

                  <Pane name="analyses">
                    <TabCase value={Analyses.Size}>
                      {currentSurveyId && (
                        <PlantMeanSizeAnalysisMap
                          surveyId={currentSurveyId}
                          zoneId={zoneId}
                        />
                      )}
                    </TabCase>

                    <TabCase value={Analyses.Count}>
                      {currentSurveyId && (
                        <PlantPopulationAnalysisMap
                          surveyId={currentSurveyId}
                          zoneId={zoneId}
                        />
                      )}
                    </TabCase>
                  </Pane>

                  <TabCase value={Analyses.NDVI}>
                    {!!currentSurveyId && (
                      <NDVITileLayer
                        bounds={fieldBounds}
                        path={`${config.analysisTileUrl}/ndvi/${currentSurveyId}`}
                      />
                    )}
                  </TabCase>
                  <TabCase value={Analyses.Imagery}>
                    {!!currentSurveyId && <RgbMap surveyId={currentSurveyId} />}
                  </TabCase>
                </FreshMap>
              </div>
              <div className={classes.mapActionsContainer}>
                <MapSwitch
                  label={
                    <span className={classes.zoneSwitchLabel}>
                      <ZoneIcon />
                      Zones: {isZonesVisible ? 'ON ' : 'OFF'}
                    </span>
                  }
                  checked={isZonesVisible}
                  onChange={checked => setIsZonesVisible(checked)}
                  disabled={zoneOptions.length === 0}
                />
              </div>
              {isZoneSelected &&
              selectedSurvey &&
              zoneData &&
              !isZoneWithinDate(
                zoneData,
                new Date(selectedSurvey.captured_at),
              ) ? (
                <div className={classes.mapMessageContainer}>
                  <Alert type="warning">
                    New boundaries has been uploaded since this flight
                  </Alert>
                </div>
              ) : null}
            </>
          )}
          <ToolsPanel>
            <ToolBox>
              {surveysResponse.isLoading && (
                <LoadingMessage className={classes.loadingMessage}>
                  Loading flights...
                </LoadingMessage>
              )}
              {!surveysResponse.isLoading && !surveysResponse.isIdle && (
                <form>
                  <Select
                    key={currentSurveyId}
                    labelText="Flight"
                    options={surveyOptions}
                    onChange={onSurveySelectChange}
                    defaultValue={currentSurveyId}
                  />
                </form>
              )}
              {loadingZoneData && (
                <LoadingMessage className={classes.loadingMessage}>
                  Loading zones...
                </LoadingMessage>
              )}
              {!loadingZoneData && (
                <Dropdown
                  className={classes.zoneDropdown}
                  labelText="Zone"
                  links={zoneOptions}
                  disabled={zoneOptions.length === 0}
                  defaultValue={defaultZoneValue}
                />
              )}
            </ToolBox>

            <ToolBox>
              <BoxTitle>
                {isZoneSelected ? 'Zone analyses' : 'Field analyses'}
              </BoxTitle>

              {isLoading && (
                <LoadingMessage className={classes.loadingMessage}>
                  Loading analysis...
                </LoadingMessage>
              )}

              {!isLoading && !hasAnyAnalysis && <NoAnalysisMessage />}

              {!isLoading && hasAnyAnalysis && (
                <TabList className={classes.tabs}>
                  {data[Analyses.Size] && (
                    <Tab
                      value={Analyses.Size}
                      onClick={() => fireTabAnalyticsEvent('Mean Size')}
                    >
                      Size
                    </Tab>
                  )}
                  {data[Analyses.Count] && (
                    <Tab
                      value={Analyses.Count}
                      onClick={() => fireTabAnalyticsEvent('Plant pop')}
                    >
                      Count
                    </Tab>
                  )}
                  {data[Analyses.Imagery] && (
                    <Tab
                      value={Analyses.Imagery}
                      onClick={() =>
                        fireTabAnalyticsEvent('Photographic Imagery')
                      }
                    >
                      Imagery
                    </Tab>
                  )}
                  {data[Analyses.NDVI] && (
                    <Tab
                      value={Analyses.NDVI}
                      onClick={() => fireTabAnalyticsEvent('NDVI')}
                    >
                      NDVI
                    </Tab>
                  )}
                </TabList>
              )}
              {data[Analyses.Size] && (
                <TabPanel value={Analyses.Size} className={classes.panel}>
                  <PlantMeanSizeAnalysisPanel
                    surveyId={currentSurveyId}
                    zoneId={zoneId}
                  />
                </TabPanel>
              )}
              {data[Analyses.Count] && (
                <TabPanel value={Analyses.Count} className={classes.panel}>
                  {!!currentSurveyId && (
                    <PlantPopulationAnalysisPanel
                      surveyId={currentSurveyId}
                      zoneId={zoneId}
                    />
                  )}
                </TabPanel>
              )}
              {data[Analyses.Imagery] && (
                <TabPanel value={Analyses.Imagery} className={classes.panel}>
                  <RgbMapDownloadPanel surveyId={currentSurveyId} />
                </TabPanel>
              )}
              {data[Analyses.NDVI] && (
                <TabPanel value={Analyses.NDVI} className={classes.panel}>
                  {!!currentSurveyId && <NDVIAnalysisPanel />}
                </TabPanel>
              )}

              <ExportProductFileControl
                surveyId={currentSurveyId}
                zoneId={zoneId}
                className={classes.exportButton}
              />
            </ToolBox>
            {/* Commenting out Applications panel for now */}
            {/* <Applications farmId={farmId} surveyId={currentSurveyId} /> */}
          </ToolsPanel>
        </HistogramProvider>
      </TabsContextProvider>
    </section>
  );
}
