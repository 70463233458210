import React from 'react';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { EventCategory } from 'constants/eventCategory';
import { EventAction } from 'constants/eventAction';
import { useGoogleAnalyticsEvent } from 'libraries/googleAnalytics';

type Props = {
  to: string;
  children: string;
};

const useStyles = createUseStyles(() => ({
  root: {
    color: 'inherit',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 500,
    },
  },
}));

const FieldLink = ({ to, children }: Props) => {
  const classes = useStyles();

  const fireAnalyticsEvent = useGoogleAnalyticsEvent();

  return (
    <Link
      to={to}
      className={classes.root}
      onClick={() =>
        fireAnalyticsEvent(
          EventCategory.NAVIGATION,
          EventAction.BROWSING,
          'Field',
        )
      }
    >
      {children}
    </Link>
  );
};

export default FieldLink;
