import { useContext } from 'react';
import { HistogramContext } from './HistogramContext';

const useHistogramContext = () => {
  const [data, dispatch] = useContext(HistogramContext);
  const setMinMax = (min: number, max: number) =>
    dispatch({
      type: 'SET_MIN_MAX',
      data: { min, max },
    });

  const setData = (values: number[], colourList?: string[]) =>
    dispatch({
      type: 'SET_DATA',
      data: values,
      colourList,
    });

  const setLoading = (loading: boolean) =>
    dispatch({
      type: 'SET_LOADING',
      loading,
    });

  return {
    data,
    setMinMax,
    setData,
    setLoading,
  };
};

export default useHistogramContext;
