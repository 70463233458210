/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactElement } from 'react';
import { round } from 'lodash-es';
import * as L from 'leaflet';
import ProgressiveGeoJSONRenderer from 'components/FreshMap/ProgressiveGeoJSONRenderer';
import useAnalysisPlantPopulationData from 'services/useAnalysisPlantPopulationData/useAnalysisPlantPopulationData';
import { getColourForValueFromColourList } from '../utils/colourScale.utility';
import { PLANT_POPULATION_ANALYSIS_COLOURS } from './PlantPopulationAnalysisColours';

const defaultRange = {
  lower: 0,
  upper: 10000,
};

type Props = {
  surveyId: string;
  zoneId?: string;
};

function PlantPopulationAnalysisMap({
  surveyId,
  zoneId = '',
}: Props): ReactElement | null {
  const { data: populationData } = useAnalysisPlantPopulationData(
    surveyId,
    zoneId,
  );
  const geojson = populationData?.geojson;
  const metadata = populationData?.metadata || null;

  if (!metadata || !geojson || !geojson?.features.length) return null;

  const statisticsData =
    metadata.name === 'analysis-plant_population'
      ? metadata
      : metadata.zones[zoneId];

  const {
    max,
    min,
    average_cell_area_m_square: averageCellArea,
  } = statisticsData;

  const range =
    max !== null && min !== null && averageCellArea !== null
      ? {
          upper: max / averageCellArea,
          lower: min / averageCellArea,
        }
      : defaultRange;

  return (
    <ProgressiveGeoJSONRenderer
      key={zoneId}
      pathOptions={{ renderer: L.canvas() }}
      data={geojson}
      style={feature => {
        // density in plants/m2
        const density =
          feature?.properties.value / feature?.properties.area_m_square;
        return {
          weight: 1,
          fillOpacity: 1,
          color: getColourForValueFromColourList(
            density,
            PLANT_POPULATION_ANALYSIS_COLOURS,
            range,
          ),
        };
      }}
      onEachFeature={(feature, layer) => {
        layer.bindTooltip(`${round(feature.properties.value, 0)} plants`, {
          direction: 'top',
        });
      }}
    />
  );
}

export default PlantPopulationAnalysisMap;
