import React, { ReactElement, useMemo } from 'react';
import { Bar } from '@visx/shape';
import { Group } from '@visx/group';
import { AxisBottom, CommonProps as CommonAxisProps } from '@visx/axis';
import { scaleBand, scaleLinear } from '@visx/scale';
import { useTooltip, Tooltip, defaultStyles } from '@visx/tooltip';
import { createUseStyles } from 'react-jss';
import { round } from 'lodash-es';

type Spacing = {
  left: number;
  top: number;
  right: number;
  bottom: number;
};

type Data = [number, number];
type HorizontalGradientRenderProps = {
  xScale: any;
  yScale: any;
  /** Maximum width of the graph */
  xMax: number;
  /** Maximum height of the graph */
  yMax: number;
  /** Spacings applied to the graph to create the padding and graph area */
  spacing: Spacing;
};

type Props = {
  /** Used to style the bar with any svg style attribute. Restule spread into the Bar component */
  getBarStyles: (data: Data) => any;
  getBottomAxisProps?: (props: CommonAxisProps<any>) => CommonAxisProps<any>;
  /**
   * Elements rendered within the container `svg` element,
   * Use the render props to extend `SimpleBar` with any other @visx components
   */
  children?: (
    props: HorizontalGradientRenderProps,
  ) => React.ReactNode | React.ReactNode;
  /**
   * Easy tuple data format with the first value being the x-axis domain
   * and the second value being the corresponsing y-axis domain value applied
   * to each bar
   */
  data: Data[];
  /** Container height usually supplied by ParentSize */
  height: number;
  /**
   * Use to give more or less space to the bottom and left axis labels
   * or move the chart up or down relative to the container
   * Default values: `{ left: 32, right: 16, top: 16, bottom: 24 }`
   */
  spacing?: Partial<Spacing>;
  /** To enable the tool tip return this prop */
  tooltipContent?: (data: Data) => React.ReactNode;
  /**
   * Overide the default tooltip styles.
   * Note that `top` and `left` are set internally so won't make any difference if set here
   */
  tooltipStyles?: (styles: any) => any;
  /** Container width, usually supplied by `ParentSize` component */
  width: number;
  /** draw histogram bars from start coordinates to end if true. */
  expandBars?: boolean;
};

const useStyles = createUseStyles(() => ({
  root: {
    position: 'relative',
  },
}));

const DEFAULT_TEXT_COLOUR = '#C1C4D9';

const defaultMargin = { left: 16, right: 16, top: 16, bottom: 24 };

const defaultBarStyles = {
  stroke: DEFAULT_TEXT_COLOUR,
  strokeWidth: 0.1,
  fill: '#66c3ff',
};

const defaultBottomAxisProps: CommonAxisProps<any> = {
  numTicks: 2.01,
  stroke: DEFAULT_TEXT_COLOUR,
  tickLength: 0,
  // @ts-ignore
  tickFormat: v => round(v, 1),
};

const defaultTooltipStyles = {
  ...defaultStyles,
  backgroundColor: 'white',
  color: DEFAULT_TEXT_COLOUR,
  border: '1px solid #2093ce',
};

let tooltipTimeout: number;

function SimpleBar({
  data,
  getBarStyles = () => defaultBarStyles,
  getBottomAxisProps = () => defaultBottomAxisProps,
  height,
  width,
  tooltipContent,
  tooltipStyles = styles => styles,
  spacing = defaultMargin,
  children,
}: Props): ReactElement {
  const classes = useStyles();

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<Data>();

  const margin = { ...defaultMargin, ...spacing };
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.bottom - margin.top;

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleBand<number>({
        range: [0, xMax],
        round: true,
        domain: data.map(val => val[0]),
        paddingInner: 0.4,
      }),
    [data, xMax],
  );

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [
          Math.min(...data.map(val => val[1]), 0),
          Math.max(...data.map(val => val[1]), 0),
        ],
      }),
    [data, yMax],
  );

  return (
    <div className={classes.root}>
      <svg width={width} height={height} overflow="visible">
        {typeof children === 'function'
          ? children({ xScale, yScale, xMax, yMax, spacing: margin })
          : children}

        <Group top={margin.top} left={margin.left}>
          {data.map(barData => {
            const barWidth = xScale.bandwidth();
            const barHeight = yMax - yScale(barData[1] ?? 0);
            const barX = xScale(barData[0]);
            const barY = yMax - barHeight;

            return (
              <Bar
                key={`bar--${barData[0]}`}
                x={barX}
                y={barY}
                width={barWidth}
                height={barHeight}
                {...getBarStyles(barData)}
                onMouseLeave={() => {
                  tooltipTimeout = window.setTimeout(() => {
                    hideTooltip();
                  }, 300);
                }}
                onMouseOver={() => {
                  clearTimeout(tooltipTimeout);

                  const top = yMax / 2;
                  const left = barX && barX + barWidth + margin.right;
                  showTooltip({
                    tooltipData: barData,
                    tooltipTop: top,
                    tooltipLeft: left,
                  });
                }}
              />
            );
          })}
        </Group>

        <AxisBottom
          left={margin.left}
          top={margin.top + yMax}
          scale={xScale}
          {...getBottomAxisProps(defaultBottomAxisProps)}
        />
      </svg>

      {tooltipContent && tooltipOpen && tooltipData && (
        <Tooltip
          // update tooltip bounds each render
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles(defaultTooltipStyles)}
        >
          {/* @ts-ignore */}
          {tooltipContent(tooltipData)}
        </Tooltip>
      )}
    </div>
  );
}

export default SimpleBar;
