import {
  ConfirmationIcon,
  H2,
  P,
  ActionLink,
  Colours,
  MediaQueries,
} from '@hummingbirdtechgroup/crips-ui';
import classNames from 'classnames';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { ToolsPanel, ToolBox } from 'components/Layout';
import { DefaultTheme } from 'styles/defaultTheme';
import FlightRequestBackground from './FlightRequestBackground';

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  box: {
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    [MediaQueries.medium]: {
      position: 'absolute',
      top: theme.spacing(2),
      bottom: theme.spacing(2),
      left: theme.spacing(2),
      right: theme.spacing(2),
      marginTop: 0,
    },
  },
  icon: {
    display: 'block',
    margin: [theme.spacing(15), 'auto', theme.spacing(2.5), 'auto'],
  },
  text: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: '1.6rem',
    marginBottom: '4rem',
  },
  title: {
    fontSize: '2.0rem',
    fontWeight: 'bold',
    fontFamily: ['Helvetica Neue', 'sans-serif'],
    textAlign: 'center',
  },
  paragraph: {
    fontSize: '1.4rem',
    lineHeight: '2rem',
  },
  paragraphSpacing: {
    marginBottom: '2rem',
  },
  button: {
    width: '18.1rem',
    height: '4.1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  buttonText: {
    color: Colours.white,
  },
}));

export default function Confirmation() {
  const classes = useStyles();
  return (
    <FlightRequestBackground>
      <ToolsPanel>
        <ToolBox className={classes.box}>
          <ConfirmationIcon className={classes.icon} />
          <H2 className={classes.title}>Flight request submitted</H2>
          <div className={classes.text}>
            <P
              className={classNames(
                classes.paragraph,
                classes.paragraphSpacing,
              )}
            >
              The team have received your flight request order and are now
              working on it.
            </P>
            <P className={classes.paragraph}>
              Someone will be in touch soon to confirm the next flight.
            </P>
          </div>
          <ActionLink
            className={classes.button}
            to="/flight/new"
            variant="primary"
          >
            New Flight Request
          </ActionLink>
        </ToolBox>
      </ToolsPanel>
    </FlightRequestBackground>
  );
}
