import { UserFarms_farms } from 'services/useFarmsForUser/UserFarms.gql';
import { AnalyticsFunction } from 'libraries/googleAnalytics/fireGAEvent';
import { EventCategory } from 'constants/eventCategory';
import { EventAction } from 'constants/eventAction';

export default function useFarmOptions(
  farms: (UserFarms_farms | null)[],
  fireAnalyticsEvent: AnalyticsFunction,
) {
  return farms
    .filter(farm => !!farm)
    .map(farm => ({
      to: `/farm/${farm!.id}`,
      label: farm!.name,
      onClick: () =>
        fireAnalyticsEvent(
          EventCategory.NAVIGATION,
          EventAction.BROWSING,
          'Farm',
        ),
    }));
}
