import React from 'react';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { ApolloProvider } from '@apollo/client';
import gqlClient from './gqlClient';

type Props = {
  children: React.ReactNode;
};

const FreshApolloProvider = ({ children }: Props) => {
  const config = useConfig();
  return (
    <ApolloProvider client={gqlClient(config.apiUrl)}>
      {children}
    </ApolloProvider>
  );
};

export default FreshApolloProvider;
