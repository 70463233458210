import React from 'react';
import { Tooltip } from 'react-leaflet';
import { createUseStyles } from 'react-jss';
import { round } from 'lodash-es';
import { Colours, FontWeights } from '@hummingbirdtechgroup/crips-ui';
import fonts from '@hummingbirdtechgroup/crips-ui/dist/tokens/fonts';
import {
  CentimetresSquared,
  Hectares,
} from '@hummingbirdtechgroup/wings-unit-conversion';
import { DefaultTheme } from 'styles/defaultTheme';
import { Field } from './useFields';

type Props = {
  field: Field;
};

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    minWidth: theme.spacing(25),
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    border: 'none',
    borderRadius: theme.spacing(0.5),
    padding: [0, theme.spacing(2), theme.spacing(2), theme.spacing(2)],
    backdropFilter: `blur(${theme.spacing(1)}px)`,
    '-webkit-backdrop-filter': `blur(${theme.spacing(1)}px)`,
    '&::before': {
      display: 'none',
    },
  },
  tooltipHeading: {
    fontFamily: fonts.helveticaNeue,
    fontWeight: FontWeights.Bold,
    fontSize: '1.4rem',
    lineHeight: '2rem',
    textAlign: 'center',
    color: Colours.primary,
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  fieldDetailsList: {
    marginTop: theme.spacing(1),
    borderTop: '1px solid #505271',
    paddingTop: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
  },
  detailItem: {
    width: '50%',
    columnGap: theme.spacing(2),
  },
  detailHeading: {
    fontFamily: fonts.roboto,
    fontWeight: FontWeights.Light,
    fontSize: '1.2rem',
    lineHeight: '1.6rem',
    color: '#505271',
    marginTop: theme.spacing(0.5),
  },
  detail: {
    fontFamily: fonts.roboto,
    fontWeight: FontWeights.Medium,
    fontSize: '1.4rem',
    lineHeight: '2rem',
    color: Colours.primary,
  },
}));

const BoundaryTooltip = ({ field }: Props) => {
  const { name, area, varietyName } = field;

  const classes = useStyles();

  return (
    <Tooltip direction="top" sticky className={classes.root}>
      <h2 className={classes.tooltipHeading}>Field: {name}</h2>
      <ul className={classes.fieldDetailsList}>
        <li className={classes.detailItem}>
          <h3 className={classes.detailHeading}>Area</h3>
          <p className={classes.detail}>
            <Hectares>{area}</Hectares>
          </p>
        </li>
        <li className={classes.detailItem}>
          <h3 className={classes.detailHeading}>Variety</h3>
          <p className={classes.detail}>{varietyName || 'unspecified'}</p>
        </li>
        {field.meanSize && (
          <li className={classes.detailItem}>
            <h3 className={classes.detailHeading}>Mean Size</h3>
            <p className={classes.detail}>
              <CentimetresSquared>
                {round(field.meanSize, 2)}
              </CentimetresSquared>
            </p>
          </li>
        )}
        {field.count && (
          <li className={classes.detailItem}>
            <h3 className={classes.detailHeading}>Population</h3>
            <p className={classes.detail}>{field.count.toLocaleString()}</p>
          </li>
        )}
      </ul>
    </Tooltip>
  );
};

export default BoundaryTooltip;
