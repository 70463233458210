import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { ActionButton, MediaQueries } from '@hummingbirdtechgroup/crips-ui';
import { Tooltip } from 'components';
import { DefaultTheme } from 'styles/defaultTheme';
import useAnalysisMeanSizeData from 'services/useAnalysisMeanSizeData/useAnalysisMeanSizeData';
import downloadProductFiles from '../utils/downloadProductFiles';
import Icon from './Icon';

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column',
    alignItems: 'center',
    columnGap: theme.spacing(1),
    [MediaQueries.medium]: {
      flexFlow: 'row',
    },
  },
  exportButton: {
    minWidth: 140,
  },
  tooltip: {
    marginTop: theme.spacing(1),
    [MediaQueries.medium]: {
      marginTop: 0,
    },
  },
  block: {
    display: 'block',
    whiteSpace: 'nowrap',
  },
}));

function ExportProductFileControl({
  className,
  surveyId,
  zoneId,
}: {
  className?: string;
  surveyId: string;
  zoneId?: string;
}) {
  const { data, zoneMetadata, files } = useAnalysisMeanSizeData(
    surveyId,
    zoneId,
  );
  const metadata = zoneMetadata || data?.metadata;
  const classes = useStyles();
  const disabled = !files.length;
  const toolTipText = disabled ? (
    <span className={classes.block}>Export not available</span>
  ) : (
    <span>
      <span className={classes.block}>The export is a ZIP file </span>
      <span className={classes.block}>containing CSV, KML, etc.</span>
    </span>
  );

  return (
    <div className={classNames(classes.root, className)}>
      <ActionButton
        className={classes.exportButton}
        onClick={() => downloadProductFiles(metadata?.name || '', files)}
        disabled={disabled}
      >
        Export data
      </ActionButton>
      <Tooltip
        className={classes.tooltip}
        message={toolTipText}
        position="right"
      >
        <Icon />
      </Tooltip>
    </div>
  );
}

export default ExportProductFileControl;
