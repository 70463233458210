import {
  useQuery,
  gql,
  QueryResult,
  QueryFunctionOptions,
} from '@apollo/client';
import { Field, FieldVariables } from './Field.gql';

export const FIELD = gql`
  query Field($fieldId: ID!) {
    field(id: $fieldId) {
      id
      name
      farm {
        id
        name
      }
      zones {
        id
        geometry
        area
        name
        startDate
        endDate
      }
      baseZones {
        id
        geometry
        area
      }
    }
  }
`;

export default function useField(
  fieldId = '',
): QueryResult<Field, FieldVariables> {
  const options: QueryFunctionOptions<Field, FieldVariables> = {
    skip: !fieldId,
    variables: {
      fieldId,
    },
  };
  return useQuery<Field, FieldVariables>(FIELD, options);
}
