import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  Hectares,
  LitresPerHectare,
} from '@hummingbirdtechgroup/wings-unit-conversion';
import { Application } from 'services/useApplication/useApplication';
import { BoxTitle } from 'components/Layout';
import ApplicationDetailsItem from '../components/ApplicationDetailsItem';
import ApplicationBandColor from '../components/ApplicationBandColor';

const useStyles = createUseStyles(() => ({
  subgroup: {
    border: '1px solid #C1C4D9',
    borderRadius: '4px',
    padding: '16px',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '24px',
    marginTop: '16px',
    '& > li': { display: 'flex', alignItems: 'center' },
  },
}));

function ApplicationBands({ data }: { data?: Application }) {
  const classes = useStyles();

  const {
    metadata: {
      levels,
      max_product_rate: maxProductRate,
      min_product_rate: minProductRate,
    },
  } = data || {
    metadata: { levels: [], max_product_rate: 1, min_product_rate: 0 },
  };

  return (
    <section className={classes.subgroup}>
      <BoxTitle>Product rate by bands</BoxTitle>
      <ul className={classes.list}>
        {levels.map(level => (
          <li key={level.feature_id}>
            <ApplicationBandColor
              productRate={level.product_rate}
              maxProductRate={maxProductRate}
              minProductRate={minProductRate}
            />
            <ApplicationDetailsItem
              label={`Band ${level.feature_id}`}
              value={
                <>
                  <Hectares>{level.hectares}</Hectares>
                  <LitresPerHectare>
                    {level.override_value || level.product_rate}
                  </LitresPerHectare>
                </>
              }
            />
          </li>
        ))}
      </ul>
    </section>
  );
}

export default ApplicationBands;
