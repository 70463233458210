import { useState } from 'react';
import { useAuth } from '@hummingbirdtechgroup/wings-auth';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import createFightRequestSlackMessage from 'scenes/FlightRequest/utils/createFightRequestSlackMessage';
import dateToString from 'utilities/dateToString';
import { UserFarms_farms } from '../useFarmsForUser/UserFarms.gql';
import { MapFields_mapFields } from '../useMapFields/MapFields.gql';

type Response = {
  post: (
    farm: UserFarms_farms | null | undefined,
    fields: MapFields_mapFields[],
    fromDate: Date,
    toDate: Date,
    additionalInfo: string,
  ) => void;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
};

const useFlightRequest = (): Response => {
  const { user, token } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { slackChannelFlightRequest, adminSlackBotUrl } = useConfig();

  const postMessageToSlack = (data: any) =>
    fetch(`${adminSlackBotUrl}/post-message`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        channel: slackChannelFlightRequest,
        ...data,
      }),
    });

  const postReplyToSlack = (ts: string, message: string) => {
    const data = {
      thread_ts: ts,
      blocks: [
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: message,
          },
        },
      ],
    };
    return postMessageToSlack(data);
  };

  const post = (
    farm: UserFarms_farms | null | undefined,
    fields: MapFields_mapFields[],
    fromDate: Date,
    toDate: Date,
    additionalInfo: string,
  ) => {
    if (user && farm) {
      setIsLoading(true);
      setIsSuccess(false);
      const message = createFightRequestSlackMessage({
        user,
        farm,
        fields,
        dates: [dateToString(fromDate), dateToString(toDate)],
        additionalInfo,
      });
      postMessageToSlack(message)
        .then(res => res.json())
        .then(res => {
          postReplyToSlack(res.ts, 'Boundary files received')
            .then(() =>
              postReplyToSlack(
                res.ts,
                'Boundaries QA’d and uploaded to platform',
              ),
            )
            .then(() => postReplyToSlack(res.ts, 'Pilot contacted'))
            .then(() =>
              postReplyToSlack(
                res.ts,
                'Flight confirmed with client and pilot',
              ),
            );
        })
        .then(() => {
          setIsLoading(false);
          setIsSuccess(true);
        })
        .catch(() => {
          setIsError(true);
        });
    } else {
      setIsError(true);
    }
  };
  return {
    post,
    isLoading,
    isError,
    isSuccess,
  };
};
export default useFlightRequest;
