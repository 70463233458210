/* eslint-disable @typescript-eslint/naming-convention */
import { useMemo } from 'react';
import { filterDataByZone } from 'services/useAnalysisMeanSizeData/useAnalysisMeanSizeData';
import useProductFile from 'services/useProductFile/useProductFile';
import useSurveyAnalyses from 'services/useSurveyAnalyses/useSurveyAnalyses';
import {
  PlantPopulationMetadata,
  PlantPopulationMetadataWithZones,
  ProductFileData,
} from 'types/Product';
import {
  ANALYSIS_PLANT_POPULATION_FIELD_LEVEL,
  ANALYSIS_PLANT_POPULATION_ZONE_LEVEL,
} from 'constants/productSlugs';

const useAnalysisPlantPopulationData = (surveyId?: string, zoneId?: string) => {
  const { data: analysesData, isLoading: analysesIsLoading } =
    useSurveyAnalyses(surveyId);
  const slugFilter = zoneId
    ? ANALYSIS_PLANT_POPULATION_ZONE_LEVEL
    : ANALYSIS_PLANT_POPULATION_FIELD_LEVEL;
  const plantPopulationData = analysesData?.products.find(
    ({ product_type_name, status }) =>
      product_type_name === slugFilter && status === 'Completed',
  );

  const id = plantPopulationData?.files?.[0].id;
  const signed_url = plantPopulationData?.files?.[0].signed_url;

  const { data, isLoading: fileIsLoading } = useProductFile<
    ProductFileData<PlantPopulationMetadata | PlantPopulationMetadataWithZones>
  >(id, signed_url, {
    enabled: !!signed_url,
  });

  const filteredData: typeof data = useMemo(
    () => filterDataByZone(data, zoneId),
    [data, zoneId],
  );

  const zoneMetadata =
    data?.metadata.name === 'analysis-plant_population_zone_level' && zoneId
      ? data.metadata.zones?.[zoneId || '']
      : undefined;

  return {
    data: filteredData,
    zoneMetadata,
    status: plantPopulationData?.status,
    isLoading: fileIsLoading || analysesIsLoading,
  };
};

export default useAnalysisPlantPopulationData;
