import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import format from 'date-fns/format';
import { createUseStyles } from 'react-jss';
import { useField } from 'services';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { useFileDownloader } from '@hummingbirdtechgroup/wings-request';
import { Page, PageHeader, BackLink } from 'components/Layout';
import useFieldCrop from 'services/useFieldCrop/useFieldCrop';
import { LoadingMessage, HeaderContent, NewFlightLink } from 'components';
import MapAnalyses from './MapAnalyses';
import SettingsMenu from './components/SettingsMenu';

const useStyles = createUseStyles({
  contentWrapper: {
    position: 'relative',
    height: '100%',
  },
});

export type FieldRouteParams = { fieldId: string; farmId: string };

export default function Field() {
  const classes = useStyles();
  const {
    params: { farmId, fieldId },
  } = useRouteMatch<FieldRouteParams>();
  const { data: fieldData, loading } = useField(fieldId);
  const { data: cropData, loading: loadingCrops } = useFieldCrop(fieldId);
  const baseZone = fieldData?.field?.baseZones?.[0];

  const pageTitle = fieldData?.field?.name ? (
    `Field: ${fieldData?.field?.name}`
  ) : (
    <LoadingMessage>Loading field...</LoadingMessage>
  );

  const config = useConfig();

  const { downloadFile } = useFileDownloader();

  const downloadKmlFile = () => {
    const fieldName = fieldData?.field?.name ?? '';
    const timestamp = format(new Date(), 'yyyy-MM-dd-HH-ss');
    const fileName = `${fieldName
      .toLowerCase()
      .split(' ')
      .join('-')}-${timestamp}-kml.kml`;

    downloadFile({
      domain: config.apiUrl,
      url: `/kml?field_id=${fieldId}`,
      fileName,
    });
  };

  return (
    <Page hasHeader>
      <PageHeader
        pageTitle={pageTitle}
        backLink={<BackLink to={`/farm/${farmId}`} />}
      >
        <HeaderContent
          area={baseZone?.area}
          cropName={cropData?.cropName}
          varietyName={cropData?.varietyName}
          zonesCount={fieldData?.field?.zones?.length || 0}
          loadingZones={loading}
          loadingCrops={loadingCrops}
          actions={[
            <SettingsMenu onDownload={downloadKmlFile} />,
            <NewFlightLink farmId={farmId} fieldId={fieldId} />,
          ]}
        />
      </PageHeader>

      <MapAnalyses
        containerClassName={classes.contentWrapper}
        fieldData={fieldData}
        farmId={farmId}
        fieldId={fieldId}
      />
    </Page>
  );
}
