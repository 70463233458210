/* eslint-disable @typescript-eslint/naming-convention */
import { useMemo } from 'react';
import useSurveyAnalyses from 'services/useSurveyAnalyses/useSurveyAnalyses';
import { ANALYSIS_PHOTOGRAPHIC_IMAGERY } from 'constants/productSlugs';

const COG = 'COG';

const useCOGMapData = (surveyId?: string) => {
  const { data: analysesData, isLoading } = useSurveyAnalyses(surveyId);

  const rgbCogFile = useMemo(() => {
    const latestProducts = analysesData?.products.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    );
    const rgbMapData = latestProducts?.find(
      ({ product_type_name, status }) =>
        product_type_name === ANALYSIS_PHOTOGRAPHIC_IMAGERY &&
        status === 'Completed',
    );

    return rgbMapData?.files.find(file => file.data_type === COG);
  }, [analysesData]);

  return {
    data: rgbCogFile,
    isLoading,
  };
};

export default useCOGMapData;
