import React from 'react';
import { LoadingMessage } from 'components';
import { createUseStyles } from 'react-jss';
import { DefaultTheme } from 'styles/defaultTheme';

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const LoadingAnalysisMessage = () => {
  const classes = useStyles();

  return (
    <LoadingMessage className={classes.root}>
      Loading analysis...
    </LoadingMessage>
  );
};

export default LoadingAnalysisMessage;
