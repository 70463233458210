import { UseQueryOptions, useQuery } from 'react-query';
import getProductFileByUrl from './getProductFileByUrl';

const useProductFile = <DataType>(
  fileId?: string,
  url?: string,
  options?: UseQueryOptions<DataType, Error, DataType>,
) =>
  useQuery<DataType, Error, DataType>(
    ['product-file', fileId],
    () => getProductFileByUrl(url || ''),
    options,
  );

export default useProductFile;
