import useBenchmarks from 'services/useBenchmarks/useBenchmarks';
import { AnalysesSlug } from 'types/Product';

type BenchmarkData = {
  fieldId: number;
  surveyCapturedAt: Date;
  productType?:
    | AnalysesSlug.MEAN_SIZE_FIELD_LEVEL
    | AnalysesSlug.PLANT_POPULATION_FIELD_LEVEL;
  statisticAverage?: number;
  statisticSum?: number;
};

type BenchmarksData = {
  data: BenchmarkData[];
  loading: boolean;
};

type Statistic = {
  fieldId: number;
  surveyCapturedAt: Date;
  count?: number;
  meanSize?: number;
};

type BenchmarkStatistics = {
  data: Statistic[];
  loading: boolean;
};

const EPOCH_DATE = new Date(0);

const useBenchmarkData = (fieldIds: number[]): BenchmarksData => {
  const benchmarks = useBenchmarks(fieldIds);

  return {
    data: (benchmarks.data ?? []).map(benchmark => ({
      fieldId: benchmark.field_id,
      productType: benchmark.product_type,
      surveyCapturedAt: benchmark?.survey_captured_at
        ? new Date(benchmark.survey_captured_at)
        : EPOCH_DATE,
      statisticAverage: benchmark?.statistic_average || 0,
      statisticSum: benchmark?.statistic_sum || 0,
    })),
    loading: benchmarks.isLoading,
  };
};

const getStatistics = (
  fieldIds: number[],
  Benchmarks: BenchmarkData[],
): Statistic[] => {
  const sortByFlightDate = (
    benchmarkA: BenchmarkData,
    benchmarkB: BenchmarkData,
  ) =>
    benchmarkB.surveyCapturedAt.getTime() -
    benchmarkA.surveyCapturedAt.getTime();

  const sortedBenchmarks = Benchmarks.sort(sortByFlightDate);

  return fieldIds.map(fieldId => {
    const meanSizeBenchmark = sortedBenchmarks.find(
      benchmark =>
        benchmark.productType === AnalysesSlug.MEAN_SIZE_FIELD_LEVEL &&
        benchmark.fieldId === fieldId,
    );
    const plantPopulationBenchmark = sortedBenchmarks.find(
      benchmark =>
        benchmark.productType === AnalysesSlug.PLANT_POPULATION_FIELD_LEVEL &&
        benchmark.fieldId === fieldId,
    );

    const getLatestSurveyDate = (): Date => {
      if (meanSizeBenchmark && plantPopulationBenchmark) {
        if (
          meanSizeBenchmark.surveyCapturedAt >
          plantPopulationBenchmark.surveyCapturedAt
        ) {
          return meanSizeBenchmark.surveyCapturedAt;
        }
        return plantPopulationBenchmark.surveyCapturedAt;
      }
      return EPOCH_DATE;
    };

    return {
      fieldId,
      surveyCapturedAt: getLatestSurveyDate(),
      count: plantPopulationBenchmark?.statisticSum,
      meanSize: meanSizeBenchmark?.statisticAverage,
    };
  });
};

const useBenchmarkStatistics = (
  fieldIds: number[] = [],
): BenchmarkStatistics => {
  const { data: benchmarkData, loading } = useBenchmarkData(fieldIds);

  return {
    data: loading ? [] : getStatistics(fieldIds, benchmarkData),
    loading,
  };
};

export default useBenchmarkStatistics;
