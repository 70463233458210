import { InfoCircleIcon, MediaQueries } from '@hummingbirdtechgroup/crips-ui';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { DefaultTheme } from 'styles/defaultTheme';

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    transform: 'scale(1.4)',
    margin: theme.spacing(0.75),
    height: theme.spacing(2),
    [MediaQueries.medium]: {
      transform: 'scale(1)',
      margin: theme.spacing(0.5),
    },
  },
}));

const Icon = () => {
  const classes = useStyles();
  return (
    <span className={classes.root}>
      <InfoCircleIcon />
    </span>
  );
};

export default Icon;
