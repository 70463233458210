import React from 'react';
import { GeoJSONProps, GeoJSON, Tooltip } from 'react-leaflet';
import { Hectares } from '@hummingbirdtechgroup/wings-unit-conversion';
import { useParseGeometry } from 'services';
import { Field_field_zones } from 'services/useField/Field.gql';
import defaultTheme from 'styles/defaultTheme';

type Props = Omit<GeoJSONProps, 'data'> & {
  data?: string;
  zoneProps?: Field_field_zones;
};

const ZoneGeoJSON = ({ data, zoneProps, ...rest }: Props) => {
  const [parsedGeometry] = useParseGeometry(data);
  if (!parsedGeometry) return null;
  return (
    <GeoJSON
      data={parsedGeometry}
      pathOptions={{
        color: defaultTheme.palette.background.default,
        weight: 2,
      }}
      {...rest}
    >
      <Tooltip direction="top">
        {zoneProps?.name}
        {zoneProps?.area && (
          <>
            {' '}
            | <Hectares>{zoneProps?.area}</Hectares>
          </>
        )}
      </Tooltip>
    </GeoJSON>
  );
};

export default ZoneGeoJSON;
