import React, { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { Colours, PageHeader } from '@hummingbirdtechgroup/crips-ui';
import classNames from 'classnames';
import { DefaultTheme } from '../../styles/defaultTheme';

type Props = {
  pageTitle: ReactNode;
  className?: string;
  backLink?: React.ReactElement;
};

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    backgroundColor: Colours.white,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    position: 'relative',
  },
  backButton: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default function PageTitle({ pageTitle, className, backLink }: Props) {
  const classes = useStyles();
  return (
    <div className={classNames(classes.root, className)}>
      {backLink}
      <PageHeader>{pageTitle}</PageHeader>
    </div>
  );
}
