import { gql, QueryResult, useQuery } from '@apollo/client';
import { FieldZones, FieldZonesVariables } from './FieldZones.gql';

export const FIELDS_WITH_ZONES = gql`
  query FieldZones($farmId: ID!) {
    farm(id: $farmId) {
      id
      fields {
        id
        zones {
          name
        }
      }
    }
  }
`;

export default function useFieldZones(
  farmId?: string,
): QueryResult<FieldZones, FieldZonesVariables> {
  return useQuery<FieldZones, FieldZonesVariables>(FIELDS_WITH_ZONES, {
    skip: !farmId,
    variables: {
      farmId: farmId || '',
    },
    fetchPolicy: 'cache-first',
  });
}
