import { ApolloLink } from '@apollo/client';
import { getToken } from '@hummingbirdtechgroup/wings-auth';

const getBearer = () => {
  const token = getToken();
  return token ? `Bearer ${token}` : '';
};

export const authHandler = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: getBearer(),
    },
  }));
  return forward(operation);
});
