/* eslint-disable @typescript-eslint/naming-convention */
import { FeatureCollection } from '@turf/helpers';
import { useMemo } from 'react';
import useProductFile from 'services/useProductFile/useProductFile';
import useSurveyAnalyses from 'services/useSurveyAnalyses/useSurveyAnalyses';
import {
  MeanSizeMetadata,
  MeanSizeMetadataWithZones,
  ProductFileData,
} from 'types/Product';
import useProductDownloadFiles from '../useProductDownloadFiles/useProductDownloadFiles';
import {
  ANALYSIS_MEAN_SIZE_FIELD_LEVEL,
  ANALYSIS_MEAN_SIZE_ZONE_LEVEL,
} from '../../constants/productSlugs';

export const filterDataByZone = <DataType>(
  data?: DataType & {
    geojson: FeatureCollection;
  },
  zoneId?: string,
) =>
  zoneId && data
    ? {
        ...data,
        geojson: {
          ...data.geojson,
          features: data.geojson.features.filter(
            feat => feat.properties?.zone_id === zoneId,
          ),
        },
      }
    : data;

const useAnalysisMeanSizeData = (surveyId?: string, zoneId?: string) => {
  const { data: analysesData, isLoading: analysesIsLoading } =
    useSurveyAnalyses(surveyId);
  const slugFilter = zoneId
    ? ANALYSIS_MEAN_SIZE_ZONE_LEVEL
    : ANALYSIS_MEAN_SIZE_FIELD_LEVEL;
  const meanSizeData = analysesData?.products.find(
    ({ product_type_name, status }) =>
      product_type_name === slugFilter && status === 'Completed',
  );

  const id = meanSizeData?.files?.[0].id;

  const signed_url = meanSizeData?.files?.[0].signed_url;

  const { data, isLoading: fileIsLoading } = useProductFile<
    ProductFileData<MeanSizeMetadata | MeanSizeMetadataWithZones>
  >(id, signed_url, {
    enabled: !!signed_url,
  });

  const filteredData: typeof data = useMemo(
    () => filterDataByZone(data, zoneId),
    [data, zoneId],
  );

  const zoneMetadata =
    data?.metadata.name === 'analysis-mean_size_zone_level' && zoneId
      ? data.metadata.zones?.[zoneId || '']
      : undefined;

  const files = useProductDownloadFiles(
    analysesData?.products || [],
    slugFilter,
  );

  return {
    files,
    data: filteredData,
    zoneMetadata,
    status: meanSizeData?.status,
    isLoading: fileIsLoading || analysesIsLoading,
  };
};

export default useAnalysisMeanSizeData;
