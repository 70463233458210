import React, { useState } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { Colours, FontWeights } from '@hummingbirdtechgroup/crips-ui';
import fonts from '@hummingbirdtechgroup/crips-ui/dist/tokens/fonts';
import { DefaultTheme } from 'styles/defaultTheme';

type Props = {
  children: string;
  onClick: () => void;
  className?: string;
};

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    cursor: 'pointer',
    position: 'relative',
    transition: 'background-color 0.3s',
    '&::before': {
      height: 14,
      width: 14,
      fontSize: 14,
      border: '1px solid black',
      borderRadius: '50%',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      lineHeight: 1,
      textAlign: 'center',
    },
    '&:hover': {
      background: Colours.white,
    },
  },
  panel: {
    background: 'rgba(255, 255, 255, 0.5)',
  },
  description: {
    display: 'block',
    width: theme.spacing(7),
    minHeight: theme.spacing(3),
    paddingTop: theme.spacing(0.75),
    fontSize: 12,
    lineHeight: `${theme.spacing(2)}px`,
    fontFamily: fonts.roboto,
    fontWeight: FontWeights.Regular,
    position: 'absolute',
    textAlign: 'center',
    left: -theme.spacing(7.5),
    top: 0,
    color: Colours.primary,
    borderRadius: theme.spacing(0.25),
    opacity: 0,
    transition: 'opacity 0.3s',
    background: Colours.white,
  },
  showDescription: {
    opacity: 1,
  },
}));

const ZoomButton = ({ children, onClick, className }: Props) => {
  const [isHover, setIsHover] = useState(false);
  const classes = useStyles();

  return (
    <button
      className={classNames(classes.root, classes.panel, className)}
      onClick={onClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <span
        className={classNames(classes.panel, classes.description, {
          [classes.showDescription]: isHover,
        })}
      >
        {children}
      </span>
    </button>
  );
};

export default ZoomButton;
