import React, { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import fonts from '@hummingbirdtechgroup/crips-ui/dist/tokens/fonts';
import { Colours, FontWeights } from '@hummingbirdtechgroup/crips-ui';

type Props = {
  children: ReactNode;
};

export const styles = {
  fontFamily: fonts.helveticaNeue,
  color: Colours.primary,
  fontSize: '1.4rem',
  lineHeight: '2rem',
  fontWeight: FontWeights.Medium,
};

const useStyles = createUseStyles(() => ({
  root: styles,
}));

const ToolBoxHeader = ({ children }: Props) => {
  const classes = useStyles();

  return <h2 className={classes.root}>{children}</h2>;
};

export default ToolBoxHeader;
