import React from 'react';
import {
  ArrowDownIcon,
  MenuContext,
  MenuItem,
  MenuList,
  MenuToggle,
  SettingsIcon,
  MediaQueries,
} from '@hummingbirdtechgroup/crips-ui';
import { Tooltip } from 'components';
import { createUseStyles } from 'react-jss';
import { DefaultTheme } from 'styles/defaultTheme';

type Props = {
  onDownload: () => void;
};

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1.5),
    paddingBottom: theme.spacing(1),
    [MediaQueries.medium]: {
      borderRight: '1px solid #EAEDF5',
      marginRight: theme.spacing(2),
      paddingTop: theme.spacing(1.5),
    },
  },
}));

const SettingsMenu = ({ onDownload }: Props) => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <MenuContext>
        <Tooltip message="Settings">
          <MenuToggle>
            <SettingsIcon />
          </MenuToggle>
        </Tooltip>
        <MenuList position="left">
          <MenuItem onClick={onDownload}>
            <ArrowDownIcon />
            Download KML
          </MenuItem>
        </MenuList>
      </MenuContext>
    </div>
  );
};

export default SettingsMenu;
