import React from 'react';
import { createUseStyles } from 'react-jss';
import { Colours } from '@hummingbirdtechgroup/crips-ui';
import classNames from 'classnames';

type Props = {
  className: string;
};

const useStyles = createUseStyles(() => ({
  '@global': {
    '@keyframes rotate': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  },

  root: {
    textIndent: '-9999em',
    width: '1.4em',
    height: '1.4em',
    borderRadius: '50%',
    background: Colours.white,
    position: 'relative',
    animation: 'rotate 0.75s infinite linear',
    transform: 'translateZ(0)',
    display: 'inline-block',
    '&:before': {
      width: '50%',
      height: '50%',
      background: Colours.primary,
      borderRadius: '100% 0 0 0',
      position: 'absolute',
      top: '0',
      left: '0',
      content: "''",
    },
    '&:after': {
      background: 'white',
      width: '75%',
      height: '75%',
      borderRadius: '50%',
      content: "''",
      margin: 'auto',
      position: 'absolute',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
    },
  },
}));

const Spinner = ({ className }: Props) => {
  const classes = useStyles();

  return <span className={classNames(classes.root, className)} />;
};

export default Spinner;
