import { createTileLayerComponent, updateGridLayer } from '@react-leaflet/core';
import type { LeafletContextInterface } from '@react-leaflet/core';
import { colourisedTiles } from './ColourisedTiles';
import type { ColourisedTileOptions } from './ColourisedTiles';

function createColourisedTileLayer(
  { ...options }: ColourisedTileOptions,
  context: LeafletContextInterface,
) {
  const instance = colourisedTiles(options);
  instance.once('load', () =>
    options?.getTileValues?.(instance.getHistogram()),
  );

  return {
    instance,
    context,
  };
}

function updateColourisedTileLayer(
  // todo:<wings-geo> update instance type after converting LeafletCanvasLayer to ts - SFR 2021/01/18
  layer: any,
  props: ColourisedTileOptions,
  prevProps: ColourisedTileOptions,
) {
  const { min, max } = props;
  if (prevProps.min !== min || prevProps.max !== max) {
    layer.updateColourMap(min, max);
  }

  updateGridLayer(layer, props, prevProps);
}

const ColourisedTileLayer = createTileLayerComponent(
  createColourisedTileLayer,
  updateColourisedTileLayer,
);

export default ColourisedTileLayer;
