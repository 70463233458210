import { gql, QueryResult, useQuery } from '@apollo/client';
import { Farm, FarmVariables } from './Farm.gql';

export const FARM = gql`
  query Farm($farmId: ID!) {
    farm(id: $farmId) {
      id
      name
      bounds
    }
  }
`;

export default function useFarm(
  farmId: string,
): QueryResult<Farm, FarmVariables> {
  return useQuery<Farm, FarmVariables>(FARM, {
    skip: !farmId,
    variables: {
      farmId,
    },
  });
}
