import React, { useEffect, useState } from 'react';
import { createPathComponent, PathProps } from '@react-leaflet/core';
import { FeatureGroup, GeoJSON as LeafletGeoJSON } from 'leaflet';
import { PropsWithChildren } from '@react-leaflet/core/types/component';
import { GeoJSONProps } from 'react-leaflet';
import { FeatureCollection } from '@turf/helpers';

interface GeoJSONComponentProps extends PathProps, PropsWithChildren {
  instance: LeafletGeoJSON;
}

export const GeoJSON = createPathComponent<FeatureGroup, GeoJSONComponentProps>(
  ({ instance }, mapContext) => ({
    instance,
    context: { ...mapContext, overlayContainer: instance },
  }),
);

const PROCESSING_PARTS_COUNT = 25;

const ProgressiveGeoJSONRenderer = ({ data, ...options }: GeoJSONProps) => {
  const [leafletPath, setLeafletPath] = useState<LeafletGeoJSON | null>(null);
  const [processed, setProcessed] = useState<number>(0);

  const { features } = data as FeatureCollection;
  const { length } = features;
  const breakPoints = new Array(PROCESSING_PARTS_COUNT)
    .fill('')
    .map((item, index) => Math.ceil((length / PROCESSING_PARTS_COUNT) * index));

  useEffect(() => {
    const instance = new LeafletGeoJSON(undefined, options);
    setLeafletPath(instance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (leafletPath && processed < PROCESSING_PARTS_COUNT) {
      window.requestAnimationFrame(() => {
        for (
          let i = breakPoints[processed];
          i < (breakPoints[processed + 1] ?? length);
          // eslint-disable-next-line no-plusplus
          i++
        ) {
          const feature = features[i];
          leafletPath.addData(feature);
        }
        setProcessed(currentProcessed => currentProcessed + 1);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processed, leafletPath]);

  if (!leafletPath) return null;
  return <GeoJSON instance={leafletPath} />;
};

export default ProgressiveGeoJSONRenderer;
