import { useMemo } from 'react';
import compareDesc from 'date-fns/compareDesc';
import { Product, ProductFile } from '../../types/Product';

const TODAY = new Date();
const canFileDownload = (file: ProductFile) =>
  file.status === 'Completed' &&
  file.data_type === 'Download' &&
  compareDesc(TODAY, new Date(file?.signed_url_expiry || '')) > 0;

function useProductDownloadFiles(products: Product[], slugFilter: string) {
  const files = useMemo(() => {
    const analysis = products.find(
      product =>
        product.product_type_name === slugFilter &&
        product?.files?.find(file => canFileDownload(file)),
    );
    return analysis?.files?.filter(canFileDownload) ?? [];
  }, [products, slugFilter]);
  return files;
}

export default useProductDownloadFiles;
