import ReactGA from 'react-ga';
import { EventCategory } from 'constants/eventCategory';
import { EventAction } from 'constants/eventAction';

export type AnalyticsFunction = (
  category: EventCategory,
  action: EventAction,
  label?: string,
  value?: number,
) => void;

const fireGAEvent: AnalyticsFunction = (category, action, label, value) =>
  ReactGA.event({
    category,
    action,
    label,
    value,
  });

export default fireGAEvent;
