import { makeStyles } from '@material-ui/styles';
import { PrivateRoute } from '@hummingbirdtechgroup/wings-auth';
import { BackLink, Page, PageHeader } from 'components/Layout';
import React from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { useMapFields, useFarmsForUser, useSearchParams } from 'services';
import { Confirmation, FlightCheckout, NewFlightRequest } from './components';

const useStyles = makeStyles({
  contentWrapper: {
    position: 'relative',
    height: '100%',
  },
});

const pathIncludes = (path: string, validation: string) =>
  path.includes(validation);

export default function FlightRequest() {
  const classes = useStyles();
  const { data: farms } = useFarmsForUser();

  const [{ fields: urlFields, selectedFarm }] = useSearchParams();
  const { data: fields, loading: loadingFields } = useMapFields(selectedFarm);
  const { pathname, search } = useLocation();
  const pageTitle = `New Flight Request ${
    pathIncludes(pathname, 'checkout') ? '- submission ' : ''
  }`;

  return (
    <Page hasHeader>
      <PageHeader
        pageTitle={pageTitle}
        backLink={
          pathIncludes(pathname, 'checkout') ? (
            <BackLink
              tooltipInfo="Back to field selection"
              to={`${pathname.replace('checkout', 'new')}${search}`}
            />
          ) : undefined
        }
      />
      <div className={classes.contentWrapper}>
        <Switch>
          <PrivateRoute path="/flight/new">
            <NewFlightRequest
              fields={fields}
              urlFields={urlFields}
              farms={farms}
              loadingFields={loadingFields}
            />
          </PrivateRoute>
          <PrivateRoute path="/flight/checkout">
            <FlightCheckout />
          </PrivateRoute>
          <PrivateRoute path="/flight/confirmation" exact>
            <Confirmation />
          </PrivateRoute>
        </Switch>
      </div>
    </Page>
  );
}
