import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  ActionButton,
  Colours,
  Dropdown,
  FontWeights,
  H2,
  MediaQueries,
  P,
  Panel,
} from '@hummingbirdtechgroup/crips-ui';
import { Hectares } from '@hummingbirdtechgroup/wings-unit-conversion';
import { createUseStyles } from 'react-jss';
import { useHistory, useLocation } from 'react-router-dom';
import {
  UserFarms,
  UserFarms_farms,
} from 'services/useFarmsForUser/UserFarms.gql';
import { MapFields } from 'services/useMapFields/MapFields.gql';
import { ToolsPanel } from '../../../components/Layout';
import { LoadingMessage, SearchInput } from '../../../components';
import { useSearchParams } from '../../../services';
import { DefaultTheme } from '../../../styles/defaultTheme';
import FlightRequestBackground from './FlightRequestBackground';
import FieldItem from './FieldItem';

const MIN_REQUEST_AREA = 10;

const useStyles = createUseStyles(({ spacing }: DefaultTheme) => ({
  farmSelector: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(2),
    gap: spacing(2),
    [MediaQueries.large]: {
      marginTop: spacing(2),
      width: '44rem',
    },
  },
  fieldsList: {
    display: 'flex',
    flexDirection: 'column',
    [MediaQueries.large]: {
      marginTop: spacing(2),
      paddingBottom: spacing(2),
      width: '44rem',
    },
  },
  fieldsTitle: {
    fontWeight: FontWeights.Bold,
    fontSize: 14,
    margin: spacing(2),
  },
  farmsTitle: {
    fontWeight: FontWeights.Bold,
    fontSize: 14,
  },
  fieldsWrapper: {
    padding: `${spacing(1)}px ${spacing(2)}px ${spacing(2)}px ${spacing(2)}px`,
    boxSizing: 'border-box',
    width: '100%',
  },
  searchField: {
    margin: `0 ${spacing(2)}px`,
  },
  button: {
    alignSelf: 'center',
    position: 'sticky',
    bottom: 10,
    boxShadow: `3px 0 15px ${Colours.tertiary},0 3px 15px ${Colours.tertiary}`,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  loading: {
    margin: spacing(2),
  },
  error: {
    margin: spacing(2),
    color: Colours.danger,
  },
}));

type Props = {
  farms: UserFarms | undefined;
  fields: MapFields | undefined;
  urlFields: string[];
  loadingFields: boolean;
};

type Option = {
  value: string;
  label: string;
};

type FieldListItem = {
  fieldId: string | null | undefined;
  fieldName: string | null | undefined;
  fieldArea: number | null | undefined;
  element: ReactElement;
};

const MAX_QUERRY_ARRAY_SIZE = 21;

export default function NewFlightRequest({
  farms,
  fields,
  urlFields,
  loadingFields,
}: Props) {
  const classes = useStyles();

  const [urlQueryState] = useSearchParams();

  const { search = '', selectedFarm } = urlQueryState;

  const [fieldsList, setFieldsList] = useState<FieldListItem[]>();
  const [selectOptions, setSelectOptions] = useState<Option[]>();
  const [isInsufficientArea, setIsInsufficientArea] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (farms?.farms)
      setSelectOptions(
        farms?.farms?.map((farm: UserFarms_farms | null) => ({
          value: farm?.id.toString() || '',
          label: farm?.name || '',
        })),
      );
  }, [farms]);

  useEffect(() => {
    setFieldsList(
      fields?.mapFields?.map(field => ({
        fieldId: field?.id,
        fieldName: field?.name,
        fieldArea: field?.baseZoneArea,
        element: (
          <FieldItem
            key={field?.id}
            field={field}
            selectedFarm={selectedFarm}
          />
        ),
      })),
    );
    const selectedFields = fields?.mapFields?.filter(
      field => field?.id && urlFields?.includes(field?.id),
    );
    const selectedArea = Math.round(
      selectedFields?.reduce(
        (sum, field) => (field?.baseZoneArea ?? 0) + sum,
        0,
      ) ?? 0,
    );
    setIsButtonDisabled(!urlFields || selectedArea < MIN_REQUEST_AREA);
    setIsInsufficientArea(
      Boolean(selectedArea && selectedArea < MIN_REQUEST_AREA),
    );
  }, [fields, urlFields]);

  const filteredFieldsList = useMemo(
    () =>
      fieldsList?.filter(item =>
        item.fieldName?.toLowerCase().includes(search.toLowerCase()),
      ),
    [search, fieldsList],
  );

  useEffect(() => {
    if (urlFields) {
      let fieldsArray: any = [];
      if (urlFields.length < MAX_QUERRY_ARRAY_SIZE) {
        fieldsArray = urlFields;
      } else {
        fieldsArray = Object.entries(urlFields).map(([_key, field]) => field);
      }

      if (fieldsArray.sort())
        setFieldsList((prev: FieldListItem[] | undefined) => {
          fieldsArray.sort(
            (a: number, b: number) =>
              fieldsArray.indexOf(b) - fieldsArray.indexOf(a),
          );
          return prev?.sort(
            (item: any, prevItem: any) =>
              fieldsArray.indexOf(prevItem.fieldId) -
              fieldsArray.indexOf(item.fieldId),
          );
        });
    }
  }, [urlFields, fields]);

  const submitHandler = (e: React.FormEvent): void => {
    if (!isButtonDisabled) {
      history.push(`/flight/checkout${location.search}`);
    } else {
      e.preventDefault();
    }
  };

  const farmLinks = farms?.farms
    ?.filter(farm => farm !== null)
    .map(farm => ({
      label: farm?.name || '',
      to: `/flight/new?selectedFarm=${farm?.id.toString()}`,
    }));

  const defaultFarmValue = farms?.farms?.find(
    farm => farm?.id.toString() === selectedFarm,
  )?.name;

  return (
    <FlightRequestBackground>
      <ToolsPanel>
        <Panel>
          <div className={classes.farmSelector}>
            <H2 className={classes.farmsTitle}>Select Farm</H2>

            {farms && selectedFarm && selectOptions !== undefined ? (
              <Dropdown
                labelText="Farms"
                links={farmLinks}
                defaultValue={defaultFarmValue}
              />
            ) : (
              <LoadingMessage className={classes.loading}>
                Loading farms...
              </LoadingMessage>
            )}
          </div>
        </Panel>
        <Panel className={classes.fieldsList}>
          <>
            <H2 className={classes.fieldsTitle}>Select Fields</H2>

            <SearchInput
              labelText="Search Fields"
              className={classes.searchField}
            />
            {loadingFields && (
              <LoadingMessage className={classes.loading}>
                Loading fields...
              </LoadingMessage>
            )}
            {isInsufficientArea && (
              <P className={classes.error}>
                Sum of selected fields must be at least{' '}
                <Hectares>{MIN_REQUEST_AREA}</Hectares>
              </P>
            )}
            <form className={classes.form} onSubmit={e => submitHandler(e)}>
              <section className={classes.fieldsWrapper}>
                {selectedFarm &&
                  filteredFieldsList?.map(
                    (item: FieldListItem) => item.element,
                  )}
              </section>
              <ActionButton
                disabled={isButtonDisabled}
                type="submit"
                className={classes.button}
              >
                Next
              </ActionButton>
            </form>
          </>
        </Panel>
      </ToolsPanel>
    </FlightRequestBackground>
  );
}
