import React, { useEffect } from 'react';
import type { LatLngBounds } from 'leaflet';
import { TileLayer, useMap } from 'react-leaflet';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import useCOGMapData from 'services/useRGBMapData/useRGBMapData';
import { MapZoom } from 'constants/mapZoom';
import GeoRasterLayer from 'components/GeoRasterLayer';

type Props = {
  surveyId: string;
  bounds?: LatLngBounds;
};

function RgbMap({ surveyId, bounds }: Props): React.ReactElement | null {
  const map = useMap();
  const { data: cogMapData } = useCOGMapData(surveyId);

  const isCOG = !!cogMapData;

  useEffect(() => {
    if (isCOG) {
      map.setMaxZoom(MapZoom.HIGH_RES_MAX);
    } else {
      map.setMaxZoom(MapZoom.DEFAULT_MAX);
    }

    return () => {
      map.setMaxZoom(MapZoom.DEFAULT_MAX);
    };
  }, [isCOG, map]);

  const { analysisTileUrl } = useConfig();

  if (cogMapData?.signed_url) {
    return (
      <GeoRasterLayer
        bounds={bounds}
        paths={[cogMapData.signed_url]}
        resolution={64}
        maxZoom={MapZoom.HIGH_RES_MAX}
      />
    );
  }

  return (
    <TileLayer
      url={`${analysisTileUrl}/rgb/${surveyId}/{z}/{x}/{y}.png`}
      tms
      maxZoom={MapZoom.DEFAULT_MAX}
      maxNativeZoom={MapZoom.TILE_NATIVE_ZOOM_MAX}
      bounds={bounds}
    />
  );
}

export default RgbMap;
