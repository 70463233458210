import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { useField, useSearchParams } from 'services';
import { HeaderContent, LoadingMessage, NewFlightLink } from 'components';
import { BackLink, Page, PageHeader } from 'components/Layout';
import useFieldCrop from 'services/useFieldCrop/useFieldCrop';
import MapAnalyses from 'scenes/Field/MapAnalyses';

const useStyles = createUseStyles({
  contentWrapper: {
    position: 'relative',
    height: '100%',
  },
});

export default function FieldZone() {
  const classes = useStyles();
  const {
    params: { farmId, fieldId, zoneId },
  } = useRouteMatch<{ fieldId: string; farmId: string; zoneId: string }>();
  const { data: fieldData, loading } = useField(fieldId);
  const { data: cropData, loading: loadingCrops } = useFieldCrop(fieldId);
  const [urlQueryState] = useSearchParams();
  const { surveyId } = urlQueryState;

  const zoneData = fieldData?.field?.zones?.find(zone => zone.id === zoneId);
  const loadingZoneData = !zoneData?.name;

  const pageTitle = loadingZoneData ? (
    <LoadingMessage>Loading zone...</LoadingMessage>
  ) : (
    `Zone: ${zoneData.name}`
  );

  return (
    <Page hasHeader>
      <PageHeader
        pageTitle={pageTitle}
        backLink={
          <BackLink
            to={`/farm/${farmId}/field/${fieldId}${
              surveyId ? `?surveyId=${surveyId}` : ''
            }`}
          />
        }
      >
        <HeaderContent
          area={zoneData?.area}
          cropName={cropData?.cropName}
          varietyName={cropData?.varietyName}
          zonesCount={fieldData?.field?.zones?.length || 0}
          loadingZones={loading}
          loadingCrops={loadingCrops}
          actions={[<NewFlightLink farmId={farmId} fieldId={fieldId} />]}
        />
      </PageHeader>
      <MapAnalyses
        containerClassName={classes.contentWrapper}
        fieldData={fieldData}
        farmId={farmId}
        fieldId={fieldId}
        zoneId={zoneId}
        loadingZoneData={loadingZoneData}
      />
    </Page>
  );
}
