import React from 'react';
import { ScaleControl } from 'react-leaflet';
import { createUseStyles } from 'react-jss';
import { DefaultTheme } from 'styles/defaultTheme';
import fonts from '@hummingbirdtechgroup/crips-ui/dist/tokens/fonts';
import { Colours, FontWeights } from '@hummingbirdtechgroup/crips-ui';
import {
  UnitSystem,
  useUnitSystem,
} from '@hummingbirdtechgroup/wings-unit-conversion';

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  '@global': {
    '.leaflet-right .leaflet-control': {
      marginRight: 0,
    },
    '.leaflet-bottom .leaflet-control-scale': {
      marginBottom: 0,
    },
    '.leaflet-control-scale': {
      position: 'absolute',
      right: theme.spacing(1),
      bottom: theme.spacing(3),
      background: 'rgba(255, 255, 255, 0.5)',
      borderRadius: theme.spacing(0.5),
      minWidth: theme.spacing(8),
    },
    '.leaflet-control-scale-line': {
      border: 'none',
      display: 'inline',
      zIndex: 1000,
      lineHeight: `${theme.spacing(3)}px`,
      padding: [0, theme.spacing(1)],
      fontFamily: fonts.roboto,
      fontWeight: FontWeights.Medium,
      fontSize: 14,
      color: Colours.primary,
      background: 'none',
    },
  },
}));

const MapScale = () => {
  useStyles();

  const unitSystem = useUnitSystem();

  const isMetric = unitSystem === UnitSystem.METRIC;

  return (
    <ScaleControl
      position="bottomright"
      metric={isMetric}
      imperial={!isMetric}
      maxWidth={200}
    />
  );
};

export default MapScale;
