import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  Colours,
  FontWeights,
  MediaQueries,
} from '@hummingbirdtechgroup/crips-ui';
import fonts from '@hummingbirdtechgroup/crips-ui/dist/tokens/fonts';
import {
  MetresSquared,
  PlantsPerHectare,
  PlantsPerMetreSquared,
} from '@hummingbirdtechgroup/wings-unit-conversion';
import { DefaultTheme } from 'styles/defaultTheme';
import usePlantPopulationAnalysisMetadata from './usePlantPopulationAnalysisMetadata';
import { NoAnalysisMessage } from '../NoAnalysisMessage';
import { LoadingAnalysisMessage } from '../LoadingAnalysisMessage';

type Props = {
  surveyId: string;
  zoneId?: string;
};

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    paddingTop: theme.spacing(2.25),
  },
  detailList: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
  },
  detailHeading: {
    fontSize: '12px',
    lineHeight: '16px',
    color: Colours.sexternary,
    fontFamily: fonts.roboto,
    fontWeight: FontWeights.Regular,
  },
  detailDescription: {
    fontSize: '14px',
    lineHeight: '20px',
    marginTop: theme.spacing(0.25),
    color: Colours.primary,
    fontFamily: fonts.roboto,
    fontWeight: FontWeights.Medium,
  },
  rangeValuesContainer: {
    maxWidth: theme.spacing(38),
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(2.25),
    [MediaQueries.medium]: {
      marginTop: theme.spacing(9),
      marginBottom: theme.spacing(9),
    },
  },
  rangeValuesHeader: {
    fontSize: '14px',
    lineHeight: '20px',
    color: Colours.sexternary,
    fontFamily: fonts.roboto,
    fontWeight: FontWeights.Regular,
  },
  rangeValueList: {
    display: 'flex',
    marginLeft: -theme.spacing(0.5),
    marginRight: -theme.spacing(0.5),
    justifyContent: 'space-between',
    [MediaQueries.medium]: {
      marginLeft: -theme.spacing(1),
      marginRight: -theme.spacing(1),
    },
  },
  rangeValue: {
    fontSize: '14px',
    lineHeight: '20px',
    marginTop: theme.spacing(2),
    color: Colours.sexternary,
    fontFamily: fonts.roboto,
    fontWeight: FontWeights.Regular,
    width: theme.spacing(5),
    textAlign: 'center',
    paddingTop: theme.spacing(5.25),
    position: 'relative',
    [MediaQueries.medium]: {
      width: theme.spacing(6),
    },
  },
  paletteBlock: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    display: 'block',
    backgroundColor: Colours.primary,
    position: 'absolute',
    top: 0,
    left: '50%',
    marginLeft: -theme.spacing(2),
  },
  message: {
    paddingTop: theme.spacing(4.25),
  },
}));

const PlantPopulationAnalysisPanel = ({ surveyId, zoneId }: Props) => {
  const { isLoading, data } = usePlantPopulationAnalysisMetadata(
    surveyId,
    zoneId,
  );

  const classes = useStyles();

  if (isLoading) {
    return <LoadingAnalysisMessage />;
  }

  if (!data) {
    return <NoAnalysisMessage className={classes.message} />;
  }

  const { gridAverage, fieldAverage, totalPlants, rangeValues } = data;

  return (
    <div className={classes.root}>
      {!isLoading && (
        <>
          <ul className={classes.detailList}>
            <li>
              <h3 className={classes.detailHeading}>Grid average</h3>
              <p className={classes.detailDescription}>
                <PlantsPerMetreSquared precision={2}>
                  {gridAverage}
                </PlantsPerMetreSquared>
              </p>
            </li>
            <li>
              <h3 className={classes.detailHeading}>Field average</h3>
              <p className={classes.detailDescription}>
                <PlantsPerHectare>{fieldAverage}</PlantsPerHectare>
              </p>
            </li>
            <li>
              <h3 className={classes.detailHeading}>Total approx.</h3>
              <p className={classes.detailDescription}>
                {totalPlants?.toLocaleString()} plants
              </p>
            </li>
          </ul>
          <div className={classes.rangeValuesContainer}>
            <h3 className={classes.rangeValuesHeader}>
              Plants per <MetresSquared precision={0}>4</MetresSquared>
            </h3>
            <ul className={classes.rangeValueList}>
              {rangeValues.map(rangeValue => (
                <li key={rangeValue.colour} className={classes.rangeValue}>
                  {rangeValue.description}
                  <span
                    className={classes.paletteBlock}
                    style={{ backgroundColor: rangeValue.colour }}
                  />
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default PlantPopulationAnalysisPanel;
