import { parse } from 'date-fns';
import { Field_field_zones } from '../../services/useField/Field.gql';
import { YYYY_MM_DD } from '../../services/useBenchmarks/useBenchmarks';

const TODAY = new Date();

function isZoneWithinDate(zone: Field_field_zones, targetDate: Date) {
  const { startDate, endDate } = zone;
  const zoneStartDate = startDate && parse(startDate, YYYY_MM_DD, TODAY);
  const zoneEndDate = endDate && parse(endDate, YYYY_MM_DD, TODAY);
  const surveyDate = targetDate;
  return (
    (!zoneStartDate || surveyDate >= zoneStartDate) &&
    (!zoneEndDate || surveyDate < zoneEndDate)
  );
}

export default isZoneWithinDate;
