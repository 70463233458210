import {
  useQuery,
  gql,
  QueryResult,
  QueryFunctionOptions,
} from '@apollo/client';
import { useAuth } from '@hummingbirdtechgroup/wings-auth';
import { UserFarms, UserFarmsVariables } from './UserFarms.gql';

export const USER_FARMS = gql`
  query UserFarms($userId: Int) {
    farms(userId: $userId) {
      id
      name
    }
  }
`;

export default function useFarmsForUser(): QueryResult<
  UserFarms,
  UserFarmsVariables
> {
  const { user } = useAuth();

  const options: QueryFunctionOptions = {
    skip: !user,
    fetchPolicy: 'cache-first',
  };

  return useQuery<UserFarms, UserFarmsVariables>(USER_FARMS, options);
}
