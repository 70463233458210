import React from 'react';
import {
  MapContainer as LeafletMapContainer,
  MapContainerProps,
} from 'react-leaflet';
import c from 'classnames';
import 'leaflet/dist/leaflet.css';
import { createUseStyles } from 'react-jss';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import GoogleLayer from './GoogleLayer';
import { MapControls } from './MapControls';

type Props = MapContainerProps;

const useStyles = createUseStyles({
  container: {
    height: '100%',
    position: 'relative',
    zIndex: 0,
  },
});

const FreshMap = ({ className, children, ...props }: Props) => {
  const classes = useStyles();
  const config = useConfig();
  return (
    <LeafletMapContainer
      zoomControl={false}
      attributionControl={false}
      className={c(classes.container, className)}
      {...props}
    >
      <GoogleLayer
        apiKey={config.googleMapsApiKey}
        {...({ type: 'satellite' } as unknown)}
      />
      <MapControls />
      {children}
    </LeafletMapContainer>
  );
};

export default FreshMap;
