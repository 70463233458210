import React from 'react';
import { createUseStyles } from 'react-jss';
import { DefaultTheme } from 'styles/defaultTheme';
import classNames from 'classnames';

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    padding: theme.spacing(2),
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
  },
}));

function NoAnalysisMessage({ className }: { className?: string }) {
  const classes = useStyles();

  return (
    <p className={classNames(classes.root, className)}>
      Data could not be retrieved, please contact support through intercom or
      via email:{' '}
      <a href="mailto:help@hummingbirdtech.com">help@hummingbirdtech.com</a>
    </p>
  );
}

export default NoAnalysisMessage;
