import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { DefaultTheme } from 'styles/defaultTheme';
import { generateBandColor } from './generateBandColor';
import { nitrogenMeanSizeApplication } from '../ApplicationConfig';

const useStyles = createUseStyles(({ spacing }: DefaultTheme) => ({
  color: {
    height: '24px',
    width: '24px',
    borderRadius: '4px',
    boxShadow: '0px 3px 3px #EAEDF5',
    margin: `0 ${spacing(1)}px`,
  },
}));

type Props = {
  productRate: number;
  maxProductRate: number;
  minProductRate: number;
};

function ApplicationBandColor({
  productRate,
  maxProductRate,
  minProductRate,
}: Props) {
  const { color } = useMemo(
    () =>
      generateBandColor(
        productRate,
        nitrogenMeanSizeApplication.colors,
        false,
        maxProductRate,
        minProductRate,
      ),
    [productRate],
  );

  const classes = useStyles();
  return <div className={classes.color} style={{ backgroundColor: color }} />;
}

export default ApplicationBandColor;
