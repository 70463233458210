import React from 'react';
import MapScale from './MapScale';
import MapZoomControls from './MapZoomControls';
import useAttributionStyles from './useAttributionStyles';

const MapControls = () => {
  useAttributionStyles();
  return (
    <>
      <MapZoomControls />
      <MapScale />
    </>
  );
};
export default MapControls;
