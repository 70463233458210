import { useMemo } from 'react';
import { ApolloError } from '@apollo/client';
import { useFieldZones } from 'services';
import {
  FieldZones,
  FieldZones_farm_fields_zones,
} from '../../services/useFieldZones/FieldZones.gql';

type ZonesMap = {
  [key in number]: FieldZones_farm_fields_zones[];
};

function createFieldsZonesMap(fieldZonesData?: FieldZones): ZonesMap {
  if (fieldZonesData?.farm?.fields) {
    return fieldZonesData.farm.fields.reduce<{
      [key in string]: FieldZones_farm_fields_zones[];
    }>((acc, curr) => {
      if (curr?.id) {
        acc[curr.id] = curr?.zones || [];
      }
      return acc;
    }, {});
  }
  return {};
}

function useZonesMap(farmId: string): {
  data: ZonesMap;
  loading: boolean;
  error?: ApolloError;
} {
  const { data: fieldZoneData, error, loading } = useFieldZones(farmId);
  const data = useMemo(
    () => createFieldsZonesMap(fieldZoneData),
    [fieldZoneData],
  );
  return { data, loading, error };
}

export default useZonesMap;
