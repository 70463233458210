import React from 'react';
import { TextInput, MagGlassIcon } from '@hummingbirdtechgroup/crips-ui';
import { useSearchParams } from 'services';
import { createUseStyles } from 'react-jss';
import { DefaultTheme } from 'styles/defaultTheme';

type Props = {
  labelText: string;
  className?: string;
  disabled?: boolean;
};

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  icon: {
    height: '100%',
    marginRight: theme.spacing(1),
    float: 'left',
  },
}));

const SearchInput = ({ labelText, className, disabled = false }: Props) => {
  const [urlQueryState, setQueryParams] = useSearchParams();

  const { search = '' } = urlQueryState;

  const classes = useStyles();

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setQueryParams({
      ...urlQueryState,
      search: event.target.value,
    });
  };

  return (
    <TextInput
      labelText={
        <>
          <MagGlassIcon className={classes.icon} />
          {labelText}
        </>
      }
      className={className}
      defaultValue={search}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export default SearchInput;
