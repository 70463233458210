import { useMemo } from 'react';
import {
  DroneIcon,
  IntercomIcon,
  LogoutIcon,
  MapIcon,
  PlusSquareIcon,
} from '@hummingbirdtechgroup/crips-ui';
import { showIntercomMessenger } from '@hummingbirdtechgroup/wings-intercom';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import { EventCategory } from 'constants/eventCategory';
import { EventAction } from 'constants/eventAction';
import { AnalyticsFunction } from 'libraries/googleAnalytics/fireGAEvent';

const useNavOptions = (
  path: string,
  params: { farmId?: string },
  search: any,
  fireAnalyticsEvent: AnalyticsFunction,
) => {
  const config = useConfig();
  return useMemo(() => {
    const farmId = params?.farmId || search?.selectedFarm;
    return [
      {
        iconElement: MapIcon,
        label: 'Map',
        to: farmId ? `/farm/${farmId}` : '/farm',
        disabled: path === '/farm' || path === '/farm/:farmId',
        selected: path.startsWith('/farm'),
      },
      {
        iconElement: PlusSquareIcon,
        label: 'Upload',
        to: config.uploaderUrl,
      },
      {
        iconElement: DroneIcon,
        label: 'Flight',
        to: farmId ? `/flight/new?selectedFarm=${farmId}` : '/flight/new',
        disabled: path === '/flight/new',
        selected: path.startsWith('/flight'),
        onClick: () =>
          fireAnalyticsEvent(
            EventCategory.FLIGHT,
            EventAction.REQUEST,
            'From Nav',
          ),
      },
      {
        iconElement: IntercomIcon,
        label: 'Support',
        onClick: () => showIntercomMessenger(),
      },
      {
        iconElement: LogoutIcon,
        label: 'Logout',
        to: '/logout',
      },
    ];
  }, [path, params, search, fireAnalyticsEvent]);
};
export default useNavOptions;
