import React from 'react';
import { useMap } from 'react-leaflet';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { DefaultTheme } from 'styles/defaultTheme';
import ZoomButton from './ZoomButton';

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(7),
    zIndex: 1000,
  },
  controlItem: {
    height: theme.spacing(3),
  },
  zoomInButton: {
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
    '&::before': {
      content: "'+'",
    },
  },
  zoomOutButton: {
    borderBottomLeftRadius: theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0.5),
    '&:before': {
      content: "'-'",
    },
  },
}));

const MapZoomControls = () => {
  const classes = useStyles();
  const mapInstance = useMap();

  return (
    <ul className={classNames(classes.root)}>
      <li className={classes.controlItem}>
        <ZoomButton
          onClick={() => mapInstance.zoomIn()}
          className={classes.zoomInButton}
        >
          zoom in
        </ZoomButton>
      </li>
      <li className={classes.controlItem}>
        <ZoomButton
          onClick={() => mapInstance.zoomOut()}
          className={classes.zoomOutButton}
        >
          zoom out
        </ZoomButton>
      </li>
    </ul>
  );
};

export default MapZoomControls;
