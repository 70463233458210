import { ungzip, Data as PakoData } from 'pako';

const getProductFileByUrl = (url: string) =>
  fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Encoding': 'gzip',
    },
  }).then(res => {
    const clonedResponse = res.clone();
    return res.json().catch(() =>
      // Function json() crashes for some older analyses, because header encoding not specified.
      // For that analyses we use arrayBuffer() with ungzip library method, which is a bit slower.
      // Remove this code when AB-1049 is fixed
      clonedResponse
        .arrayBuffer()
        .then(buff => JSON.parse(ungzip(buff as PakoData, { to: 'string' }))),
    );
  });

export default getProductFileByUrl;
