import React from 'react';
import { createUseStyles } from 'react-jss';
import { DefaultTheme } from 'styles/defaultTheme';

const useStyles = createUseStyles(
  ({ spacing, palette, typography }: DefaultTheme) => ({
    root: { padding: `0 ${spacing(1)}px` },
    label: {
      color: palette.text.secondary,
      fontSize: typography.fontSizeSmall,
      fontWeight: typography.fontWeightLight,
      lineHeight: '22px',
    },
    value: {
      color: '#222239',
      fontSize: typography.fontSize,
      fontWeight: typography.fontWeightRegular,
      lineHeight: '22px',
    },
  }),
);

function ApplicationDetailsItem({
  label,
  value,
}: {
  label: string;
  value?: string | number | React.ReactElement;
}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <p className={classes.label}>{label}</p>
      <p className={classes.value}>{value}</p>
    </div>
  );
}

export default ApplicationDetailsItem;
