import { gql, QueryResult, useQuery } from '@apollo/client';
import { FieldCrops, FieldCropsVariables } from './FieldCrops.gql';

export const FIELDS_WITH_CROPS = gql`
  query FieldCrops($farmId: ID!) {
    baseZones(farmId: $farmId) {
      fieldId
      zoneCroppings {
        variety {
          name
          crop {
            name
          }
        }
      }
    }
  }
`;

export default function useFieldCrops(
  farmId?: string,
): QueryResult<FieldCrops, FieldCropsVariables> {
  return useQuery<FieldCrops, FieldCropsVariables>(FIELDS_WITH_CROPS, {
    skip: !farmId,
    variables: {
      farmId: farmId || '',
    },
    fetchPolicy: 'cache-first',
  });
}
