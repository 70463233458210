import React from 'react';
import {
  CentimetresSquared,
  useUnitSystem,
  getValue,
  getSymbol,
} from '@hummingbirdtechgroup/wings-unit-conversion';
import useAnalysisMeanSizeData from 'services/useAnalysisMeanSizeData/useAnalysisMeanSizeData';
import { createUseStyles } from 'react-jss';
import { DefaultTheme } from 'styles/defaultTheme';
import { Colours, FontWeights } from '@hummingbirdtechgroup/crips-ui';
import fonts from '@hummingbirdtechgroup/crips-ui/dist/tokens/fonts';
import { round } from 'lodash-es';
import { ParentSize } from '@visx/responsive';
import { SimpleBar } from '../SimpleBar';
import { getColourByPercentileRange } from '../utils/colourScale.utility';
import usePercentileFeaturesRange from './usePercentileFeaturesRange';
import { PLANT_MEAN_SIZE_ANALYSIS_COLOURS } from './PlantMeanSizeAnalysisColours';
import { NoAnalysisMessage } from '../NoAnalysisMessage';
import { LoadingAnalysisMessage } from '../LoadingAnalysisMessage';

const useStyles = createUseStyles(({ spacing }: DefaultTheme) => ({
  root: {
    paddingTop: spacing(2.25),
  },
  detailList: {
    display: 'flex',
    gap: spacing(2),
    justifyContent: 'space-between',
  },
  detailHeading: {
    fontSize: '12px',
    lineHeight: '16px',
    color: Colours.sexternary,
    fontFamily: fonts.roboto,
    fontWeight: FontWeights.Regular,
  },
  detailDescription: {
    fontSize: '14px',
    lineHeight: '20px',
    marginTop: spacing(0.25),
    color: Colours.primary,
    fontFamily: fonts.roboto,
    fontWeight: FontWeights.Medium,
  },
  histogramContainer: {
    marginTop: spacing(3),
  },
}));

type Props = {
  surveyId: string;
  zoneId?: string;
};

type Data = [number, number];

function getTrimEnds(
  meanPlantSize: number,
  standardDeviation: number,
  binStepSize: number,
  histogramValues: number[],
) {
  return [
    Math.max(0, round((meanPlantSize - standardDeviation * 2) / binStepSize)),
    Math.min(
      histogramValues.length - 1,
      round((meanPlantSize + standardDeviation * 2) / binStepSize),
    ),
  ];
}

function PlantMeanSizeAnalysisPanel({
  surveyId,
  zoneId,
}: Props): React.ReactElement {
  const classes = useStyles();

  const { data, zoneMetadata, isLoading } = useAnalysisMeanSizeData(
    surveyId,
    zoneId,
  );

  const unitSystem = useUnitSystem();

  const metadata = zoneMetadata || data?.metadata;
  const binStepSize = metadata?.histogram_bin_step || 10;
  const histogramValues = metadata?.histogram_values || [];
  const meanPlantSize = metadata?.mean_plant_size || 0;
  const standardDeviation = metadata?.std_deviation || 0;
  const features = data?.geojson.features || [];

  const percentileRange = usePercentileFeaturesRange(features);

  const transformedPercentileRange = percentileRange.map(percentile =>
    round((binStepSize * percentile) / 100),
  );

  const [trimStart, trimEnd] = getTrimEnds(
    meanPlantSize,
    standardDeviation,
    binStepSize,
    histogramValues,
  );

  if (trimStart > transformedPercentileRange[0]) {
    transformedPercentileRange[0] = trimStart;
  }
  if (
    trimEnd < transformedPercentileRange[transformedPercentileRange.length - 1]
  ) {
    transformedPercentileRange[transformedPercentileRange.length - 1] = trimEnd;
  }

  const trimmedData: Data[] = histogramValues
    .map((y, x) => [x, y])
    .slice(trimStart, trimEnd + 1) as Data[];

  return (
    <div className={classes.root}>
      {isLoading && <LoadingAnalysisMessage />}

      {!isLoading && !metadata && <NoAnalysisMessage />}

      {!isLoading && metadata && (
        <>
          <ul className={classes.detailList}>
            <li>
              <h3 className={classes.detailHeading}>Mean size</h3>
              <p className={classes.detailDescription}>
                <CentimetresSquared>{meanPlantSize}</CentimetresSquared>
              </p>
            </li>
            <li>
              <h3 className={classes.detailHeading}>Standard deviation</h3>
              <p className={classes.detailDescription}>
                <CentimetresSquared>{standardDeviation}</CentimetresSquared>
              </p>
            </li>
            <li>
              <h3 className={classes.detailHeading}>Variation</h3>
              <p className={classes.detailDescription}>
                {round((standardDeviation / meanPlantSize) * 100)} %
              </p>
            </li>
          </ul>

          <ParentSize className={classes.histogramContainer}>
            {({ width }) => (
              <SimpleBar
                expandBars
                height={180}
                width={width as number}
                data={trimmedData}
                getBarStyles={barData => ({
                  fill: getColourByPercentileRange(
                    percentileRange,
                    PLANT_MEAN_SIZE_ANALYSIS_COLOURS,
                    barData[0] * binStepSize,
                  ),
                })}
                getBottomAxisProps={defaultProps => ({
                  ...defaultProps,
                  label: `Plant size (${getSymbol(
                    'centimetres-square',
                    unitSystem,
                  )})`,
                  numTicks: 0,
                  hideZero: true,
                  hideTicks: true,
                  tickLength: 0,
                  tickValues: transformedPercentileRange,
                  tickFormat: (value: any) =>
                    `${getValue(
                      value * 10,
                      'centimetres-square',
                      unitSystem,
                      0,
                    )}`,
                })}
              />
            )}
          </ParentSize>
        </>
      )}
    </div>
  );
}

export default PlantMeanSizeAnalysisPanel;
