import { ProductFile } from 'types/Product';
import { EventCategory } from 'constants/eventCategory';
import { EventAction } from 'constants/eventAction';
import { fireGAEvent } from 'libraries/googleAnalytics';
import fileDownloader from '../../../../utilities/fileDownloader';

const downloadProductFiles = (fileName: string, files: ProductFile[]) =>
  files.forEach(file => {
    try {
      fireGAEvent(EventCategory.ANALYSIS, EventAction.ZIP_DOWNLOAD);
      fileDownloader(file.signed_url, fileName);
    } catch (err) {
      console.error(`Failed to download ${fileName} file`);
    }
  });

export default downloadProductFiles;
