import { ApplicationType } from 'types/ApplicationType';
import { ApplicationKey } from 'types/ApplicationKey';
import { AnalysisType } from 'types/AnalysesType';

export interface ApplicationConfig {
  id: number;
  type: ApplicationType;
  displayName: string;
  url: ApplicationKey;
  base: AnalysisType;
  crops?: Record<string, any>;
  unit: string;
  barStep: number;
  defaultRange: [number, number];
  colors: string[];
}

export const nitrogenMeanSizeApplication: ApplicationConfig = {
  id: 36,
  type: 'nitrogenMeanSizeApplication',
  displayName: 'Nitrogen (Mean size) (Contoured)',
  url: 'application-nitrogen/analysis-mean_size',
  base: 'plantMeanSize',
  crops: {
    Lettuce: true,
    Broccoli: true,
    Celery: true,
  },
  unit: 'unit.kilogramsPerHectare',
  barStep: 1,
  defaultRange: [0, 120],
  colors: ['#FFF987', '#FBAD5E', '#DD635A', '#A12660', '#4A0B5E'],
};
