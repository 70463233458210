import { useMemo } from 'react';
import { Field_field_zones } from 'services/useField/Field.gql';
import { AnalyticsFunction } from 'libraries/googleAnalytics/fireGAEvent';
import { EventCategory } from 'constants/eventCategory';
import { EventAction } from 'constants/eventAction';

const getSurveyParam = (surveyId?: string) =>
  surveyId ? `?surveyId=${surveyId}` : '';

const useZoneOptions = (
  zones: Field_field_zones[],
  farmId: string,
  fieldId: string,
  fireAnalyticsEvent: AnalyticsFunction,
  surveyId?: string,
) =>
  useMemo(
    () =>
      zones.map(zone => ({
        to: `/farm/${farmId}/field/${fieldId}/zone/${zone.id}${getSurveyParam(
          surveyId,
        )}`,
        label: zone.name,
        onClick: () =>
          fireAnalyticsEvent(
            EventCategory.NAVIGATION,
            EventAction.BROWSING,
            'Zone',
          ),
      })),
    [zones],
  );

export default useZoneOptions;
