import React from 'react';
import { createUseStyles } from 'react-jss';
import { ArrowDownIcon, Colours } from '@hummingbirdtechgroup/crips-ui';
import useCOGMapData from 'services/useRGBMapData/useRGBMapData';
import { DefaultTheme } from 'styles/defaultTheme';
import { useGoogleAnalyticsEvent } from 'libraries/googleAnalytics';
import { EventCategory } from 'constants/eventCategory';
import { EventAction } from 'constants/eventAction';

type Props = { surveyId: string };

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    overflow: 'hidden',
    textAlign: 'center',
  },
  link: {
    color: Colours.primary,
    border: `1px solid ${Colours.quinternary}`,
    backgroundColor: Colours.white,
    lineHeight: '4.1rem',
    display: 'inline-block',
    fontSize: '1.4rem',
    lineSpacing: theme.spacing(3),
    borderRadius: theme.spacing(0.5),
    margin: `${theme.spacing(16)}px auto 0 auto`,
    minWidth: theme.spacing(29),
    '&:hover': {
      backgroundColor: Colours.quarternary,
    },
  },
  icon: {
    marginRight: theme.spacing(1),
    position: 'relative',
    top: '3px',
  },
}));

function RgbMapDownloadPanel({ surveyId }: Props) {
  const classes = useStyles();
  const { data: cogMapData } = useCOGMapData(surveyId);
  const analyticsEvent = useGoogleAnalyticsEvent();
  const signedUrl = cogMapData?.signed_url;
  if (!signedUrl) return null;

  return (
    <div className={classes.root}>
      <a
        href={signedUrl}
        className={classes.link}
        onClick={() =>
          analyticsEvent(EventCategory.ANALYSIS, EventAction.DOWNLOAD)
        }
      >
        <ArrowDownIcon className={classes.icon} />
        Download imagery data
      </a>
    </div>
  );
}

export default RgbMapDownloadPanel;
