import { useMemo } from 'react';
import { Feature } from '@turf/helpers';
import { getPercentileRange } from '../utils/colourScale.utility';

function usePercentileFeaturesRange(features: Feature[]) {
  return useMemo(() => {
    const meanSizes = features
      .map(feature => feature.properties?.value)
      .filter(meanSize => !!meanSize);
    return getPercentileRange(meanSizes);
  }, [features]);
}

export default usePercentileFeaturesRange;
