import React from 'react';
import { createUseStyles } from 'react-jss';
import { FontWeights, FontSizes, H2 } from '@hummingbirdtechgroup/crips-ui';

const useStyles = createUseStyles(() => ({
  root: {
    fontWeight: FontWeights.Medium,
    fontSize: FontSizes.Big,
    lineHeight: 'initial',
  },
}));

function GroupTitle({ children }: { children: React.ReactNode }) {
  const classes = useStyles();
  return <H2 className={classes.root}>{children}</H2>;
}

export default GroupTitle;
