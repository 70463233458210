import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { useMediaQuery } from 'react-responsive';
import { MapContainerProps } from 'react-leaflet';
import { Dropdown, P, Breakpoints } from '@hummingbirdtechgroup/crips-ui';
import { useFarmsForUser, useFarm, useSearchParams } from 'services';
import defaultTheme, { DefaultTheme } from 'styles/defaultTheme';
import { Page, ToolBox, ToolBoxHeader, ToolsPanel } from 'components/Layout';
import { LoadingMessage, SearchInput } from 'components';
import FreshMap from 'components/FreshMap';
import { useGoogleAnalyticsEvent } from 'libraries/googleAnalytics';
import { FieldRouteParams } from '../Field/Field';
import useFarmOptions from './useFarmOptions';
import useFields from './useFields';
import SortSelect from './SortSelect';
import FieldItem from './FieldItem';
import FieldBoundary from './FieldBoundary';
import useSortedFields from './useSortedFields';

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  mapContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
  },
  searchFilter: {
    flexGrow: 1,
  },
  sortFilter: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    width: theme.spacing(19),
  },
  dropdown: {
    marginTop: theme.spacing(1),
  },
  loadingMessage: {
    marginTop: theme.spacing(2),
  },
  noFields: {
    padding: `${theme.spacing(3)}px ${theme.spacing(1.5)}px`,
    '& > p': {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '18px',
    },
  },
}));

const Farm = () => {
  const { data: farmsForUserData, loading: farmsDataLoading } =
    useFarmsForUser();
  const {
    params: { farmId },
  } = useRouteMatch<FieldRouteParams>();
  const history = useHistory();

  const farms = farmsForUserData?.farms ?? [];

  useEffect(() => {
    if (!farmId && farms.length > 0) {
      history.push(`/farm/${farms[0]?.id}`);
    }
  }, [farmId, farms]);

  const fireAnalyticsEvent = useGoogleAnalyticsEvent();

  const farmOptions = useFarmOptions(farms, fireAnalyticsEvent);

  const { data: farmData, loading: farmDataLoading } = useFarm(farmId);

  const {
    data: fields,
    loading: fieldsLoading,
    loadingCrops,
    loadingZones,
    loadingFlightDates,
  } = useFields(farmId);

  const showMap = useMediaQuery({ minWidth: Breakpoints.medium });

  const bounds = (farmData?.farm?.bounds as string | undefined)
    ? (JSON.parse(farmData?.farm?.bounds) as MapContainerProps['bounds'])
    : undefined;

  const classes = useStyles();

  const [urlQueryState] = useSearchParams();
  const { search = '' } = urlQueryState;

  const sortedFields = useSortedFields(fields);
  const filteredFields = sortedFields.filter(field =>
    field.name.toLowerCase().includes(search.toLowerCase()),
  );

  const anyDataLoading = farmsDataLoading || loadingFlightDates;

  return (
    <Page>
      <ToolsPanel>
        <ToolBox>
          <ToolBoxHeader>Select farm</ToolBoxHeader>
          {(farmsDataLoading || farmDataLoading) && (
            <LoadingMessage className={classes.loadingMessage}>
              Loading farms...
            </LoadingMessage>
          )}
          {!farmsDataLoading && !farmDataLoading && (
            <Dropdown
              labelText="Farm"
              links={farmOptions}
              defaultValue={farmData?.farm?.name}
              className={classes.dropdown}
            />
          )}
        </ToolBox>

        <ToolBox>
          <ToolBoxHeader>Select field</ToolBoxHeader>
          <form className={classes.filters}>
            <SearchInput
              labelText="Search Fields"
              className={classes.searchFilter}
              disabled={anyDataLoading}
            />
            <SortSelect
              className={classes.sortFilter}
              disabled={anyDataLoading}
            />
          </form>
          {fieldsLoading && (
            <LoadingMessage className={classes.loadingMessage}>
              Loading fields...
            </LoadingMessage>
          )}
          {!fieldsLoading && (
            <ul>
              {filteredFields.map(field => (
                <FieldItem
                  key={field.id}
                  farmId={farmId}
                  field={field}
                  loadingCrops={loadingCrops}
                  loadingZones={loadingZones}
                />
              ))}
            </ul>
          )}
          {!fieldsLoading && !fields.length && (
            <div className={classes.noFields}>
              <P>No fields have been onboarded for this farm. </P>
              <P>
                Please send us your field boundaries through Intercom by
                clicking on the icon in the left panel or email them at{' '}
                <a href="mailto:help@hummingbirdtech.com">
                  help@hummingbirdtech.com
                </a>
                .
              </P>
            </div>
          )}
        </ToolBox>
      </ToolsPanel>
      {showMap && !farmDataLoading && bounds && (
        <div className={classes.mapContainer}>
          <FreshMap
            bounds={bounds}
            boundsOptions={{ paddingTopLeft: [defaultTheme.spacing(70), 0] }}
          >
            {filteredFields
              .filter(field => !!field?.geometry)
              .map(field => (
                <FieldBoundary key={farmId} farmId={farmId} field={field} />
              ))}
          </FreshMap>
        </div>
      )}
    </Page>
  );
};

export default Farm;
