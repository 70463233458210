import React from 'react';
import { createUseStyles } from 'react-jss';
import fonts from '@hummingbirdtechgroup/crips-ui/dist/tokens/fonts';
import { Colours, FontWeights } from '@hummingbirdtechgroup/crips-ui';
import { DefaultTheme } from 'styles/defaultTheme';
import classNames from 'classnames';
import Spinner from './Spinner';

type Props = {
  className?: string;
  children?: string;
  variant?: 'small' | 'medium';
};

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    fontFamily: fonts.helveticaNeue,
    color: Colours.primary,

    fontWeight: FontWeights.Regular,
  },
  spinner: {
    marginRight: theme.spacing(1),
    float: 'left',
  },
  small: { fontSize: '1.2rem', lineHeight: '1.6rem' },
  medium: { fontSize: '1.4rem', lineHeight: '2rem' },
}));

const LoadingMessage = ({ className, variant = 'medium', children }: Props) => {
  const classes = useStyles();

  return (
    <p className={classNames(classes.root, className, classes[variant])}>
      <Spinner className={classes.spinner} />
      {children || 'Loading...'}
    </p>
  );
};

export default LoadingMessage;
