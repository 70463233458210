import { useQuery, UseQueryResult } from 'react-query';
import { get } from '@hummingbirdtechgroup/wings-request';
import { useConfig } from '@hummingbirdtechgroup/wings-config';
import Survey from './Survey';

type Response = {
  surveys: Survey[];
  data: Survey[];
  isLoading: boolean;
  isError: boolean;
};

const useSurveys = (fieldId?: string): UseQueryResult<Response> => {
  const config = useConfig();
  return useQuery(
    ['surveys', fieldId],
    () => get(config.apiUrl, `/v1/surveys/?field_id=${fieldId}`),
    { enabled: Boolean(fieldId) },
  );
};

export default useSurveys;
