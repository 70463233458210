import { round } from 'lodash-es';
import useAnalysisPlantPopulationData from 'services/useAnalysisPlantPopulationData/useAnalysisPlantPopulationData';
import { PLANT_POPULATION_ANALYSIS_COLOURS } from './PlantPopulationAnalysisColours';
import { getRangedPalette } from '../utils/colourScale.utility';

const MSQ_CONVERT = 10000;

// some analysis layers are being created without a grid size, use the default grid size until the bug is fixed
const DEFAULT_GRID_SIZE = 2;

type RangeValue = {
  colour: string;
  description: string;
};

type PlantPopulationAnalysisMetadata = {
  isLoading: boolean;
  data?: {
    gridAverage: number;
    fieldAverage: number;
    totalPlants: number;
    rangeValues: RangeValue[];
    gridSize: number;
    name: string;
  };
};

const usePlantPopulationAnalysisMetadata = (
  surveyId: string,
  zoneId?: string,
): PlantPopulationAnalysisMetadata => {
  const {
    isLoading,
    data: plantPopulationData,
    zoneMetadata,
  } = useAnalysisPlantPopulationData(surveyId, zoneId);

  const metadata = zoneId ? zoneMetadata : plantPopulationData?.metadata;

  let data;
  if (metadata) {
    const {
      max,
      min,
      total_area_m_square: totalArea,
      total_plants: totalPlants,
      average_plants_per_cell: averagePlantsPerCell,
      average_cell_area_m_square: averageCellArea,
    } = metadata;

    // different analyses may have different capitalization for grid size key
    const gridSize =
      metadata.grid_size ?? metadata.gridSize ?? DEFAULT_GRID_SIZE;

    const gridAverage = round(averagePlantsPerCell / averageCellArea, 2);
    const fieldAverage = round((totalPlants / totalArea) * MSQ_CONVERT);

    const upperRange = max / averageCellArea;
    const lowerRange = min / averageCellArea;

    const rangedPalette = getRangedPalette(PLANT_POPULATION_ANALYSIS_COLOURS, {
      upper: upperRange,
      lower: lowerRange,
    });

    const rangeValues = rangedPalette.map(palette => ({
      colour: palette.colour,
      description: `${round(gridSize * gridSize * palette.from)}-${round(
        gridSize * gridSize * palette.to,
      )}`,
    }));

    data = {
      gridAverage,
      fieldAverage,
      totalPlants,
      rangeValues,
      gridSize,
      name: metadata?.name || '',
    };
  }

  return {
    isLoading,
    data,
  };
};

export default usePlantPopulationAnalysisMetadata;
