import { useMemo } from 'react';
import { Field } from './useFields';
import { useSearchParams } from '../../services';
import { SORT_BY_DEFAULT, SORT_BY_LATEST_FLIGHT } from './SortSelect';

const sortByLatestFlight = (fieldA: Field, fieldB: Field) =>
  fieldB.latestFlightDate.getTime() - fieldA.latestFlightDate.getTime();

const useSortedFields = (fields: Field[]) => {
  const [urlQueryState] = useSearchParams();
  return useMemo(() => {
    const { sort = SORT_BY_DEFAULT } = urlQueryState;
    return sort === SORT_BY_LATEST_FLIGHT
      ? fields.sort(sortByLatestFlight)
      : fields.sort();
  }, [fields]);
};

export default useSortedFields;
