import React, { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { DefaultTheme } from 'styles/defaultTheme';
import { hideScrollbar } from 'styles';
import { MediaQueries } from '@hummingbirdtechgroup/crips-ui';

type Props = {
  children: ReactNode;
};

const useStyles = createUseStyles((theme: DefaultTheme) => ({
  root: {
    [MediaQueries.medium]: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: theme.spacing(60),
      backdropFilter: 'blur(4px)',
      backgroundColor: 'rgba(255, 255, 255, 0.25)',
      zIndex: 200,
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      overflowY: 'scroll',
      ...hideScrollbar,
    },
  },
}));

const ToolsPanel = ({ children }: Props) => {
  const classes = useStyles();
  return <div className={classNames(classes.root)}>{children}</div>;
};

export default ToolsPanel;
